import React, { useEffect, useState } from "react";
import Button from "../../common/button";
import { AiFillGoogleCircle, AiFillFacebook } from "react-icons/ai";
import { addUser } from "../../../network/user/user";
import { useNavigate, useLocation } from "react-router-dom";
import EmailValidationModal from "../../modals/emailValidationModal/emailValidationModal";
import Title from "../../common/title/title";
import "./styles.css";
import LoginForm from "../../forms/loginForm/loginForm";
import RegisterForm from "../../forms/registerForm/registerForm";

const Register = () => {

  const [registrationData, setRegistrationData] = useState({});
  const [isRegisterLoading, setIsRegisterLoading] = useState(true);
  const [registerError, setRegisterError] = useState(null);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [registerData, setRegisterData] = useState({
    userType: "regular",
    selectedPlan: null,
    firstName: null,
    lastName: null,
    username: null,
    email: null,
    password: null,
    billingFirstName: null,
    billingLastName: null,
    companyName: null,
    country: null,
    streetAddress: null,
    postalCode: null,
    city: null,
    billingEmail: null,
    isCreditCard: true,
    isPaypal: false,
    cardNumber: null,
    expiryDate: null,
    cardCode: null,
  });


  useEffect(() => {

    setRegisterError(null);

    if(!registerData.email){
      return;
    }

    setShowValidationModal(true);
    addUser(registerData, (response)=>{

      if(response.error){
        setRegisterError(response.error);
        setIsRegisterLoading(false);
        return;
      }
      setRegistrationData(response);
      setIsRegisterLoading(false);
    });
  }, [registerData])

  const updateData = (newData) => {
    setRegisterData((oldData) => ({ ...oldData, ...newData }))
  };

  useEffect(() => {

    const planData = location.state?.planData;

    if (!planData) {
      navigate("/pricing");
    }

    setRegisterData((registerData) => ({
      ...registerData,
      selectedPlan: planData,
    }));
  }, []);

  return (
    <div className="registerPage standarSidePadding">
      <div className="loginStep">
        <div className="loginTitle">
          <Title size="l" hasUnderline weigth="black">
            Create your account
          </Title>
        </div>
      </div>

      <div className="loginPlan infoBg">
        <div className="selectedPlan">
          <div className="">
            Selected plan:{" "}
            <span className={"planPill"}>{registerData.selectedPlan?.name}</span>
            {" "}{registerData.selectedPlan?.name === "BASIC" ? "(Free)" : ""}
          </div>
          <div className="changePlanButton">
            <Button onClick={()=>navigate("/pricing")}>CHANGE</Button>
          </div>
        </div>
      </div>

        <div className="loginForms">
          <div className="loginForm">
            <LoginForm setData={updateData}></LoginForm>
          </div>
          <div className="registerForm">
            <RegisterForm setData={updateData}></RegisterForm>
          </div>
        </div>

      <EmailValidationModal userData={registerData} isLoading={isRegisterLoading} error={registerError} setIsOpen={setShowValidationModal} isOpen={showValidationModal}></EmailValidationModal>
    </div>
  );
};

export default Register;
