import { useState, useEffect } from 'react'

export default () => {

    const [breakpoint, setBreakpoint] = useState(window.innerWidth)
    const resize = () => {
        setBreakpoint(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])

    if (breakpoint <= 900){
        return "mobile"
    }

    if (breakpoint > 900 && breakpoint <= 1200){
        return "tablet"
    }

    if (breakpoint > 1200){
        return "desktop"
    }
}