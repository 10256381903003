import {getPricing} from '../../../../../../network/pricing/pricing';
import Button from '../../../../../common/button';
import {useEffect, useState} from 'react';
import PricingTable from './PricingTable';
import './styles.css';
import AddPricingModal from './addPricingModal';
import GetPricingModal from './getPricingModal';

const PricingAdmin = () => {

    const [pricingData, setPricingData] = useState([]);
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [getModalIsOpen, setGetModalIsOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});


    useEffect(() => {
        getPricing((response => {
            setPricingData(()=>response);
        }))
    }, [])

    const onRowClick = (pricingClicked) => {
        setSelectedRow(pricingClicked);
        setGetModalIsOpen(true);
    }

    return (
        <div className="pricingAdminPage">
            <div className="pricingAdminPageHeader">
            </div>
            {addModalIsOpen || getModalIsOpen ? <></> :  <PricingTable onRowClick={onRowClick} data={pricingData} />}
            <AddPricingModal setIsOpen={setAddModalIsOpen} isOpen={addModalIsOpen}></AddPricingModal>
            <GetPricingModal setIsOpen={setGetModalIsOpen} isOpen={getModalIsOpen} data={selectedRow} setData={setSelectedRow}></GetPricingModal>
        </div>
    )
}

export default PricingAdmin;
