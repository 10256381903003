import React, { useContext } from "react";
import Title from '../../common/title/title';
import Carousel from '../../common/carousel';
import TopicSearch from "../../common/topicSearch";
import { LanguageContext } from "../../../context/languageContex";
import "./styles.css";
import CallToAction from "../../common/cta";
import {useState, useEffect} from "react";
import {getCourses} from "../../../network/course/course";
import {useNavigate} from "react-router-dom";
import Banner from "../../common/hero banner/banner";


const Landing = ({breakpoint}) => {
  const { language } = useContext(LanguageContext);
  const [lessonData, setLessonData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCourses((response)=>{
      setLessonData(response);
     });
  }, []);

  if(lessonData.name === "AxiosError"){
    navigate("/error-page", {state:lessonData})
  }

  return (
    <>
    <Banner breakpoint={breakpoint}></Banner>
    <div className=" standarSidePadding landing">
      <div className="landingGroup">
        <Title weigth="black" size="l" hasUnderline>{language.lastestLessonPlans}</Title>
      </div>
      <div className="landingCarouselGroup">
        <Carousel items={lessonData}></Carousel>
      </div>
    </div>
    <div className="">
    <div className="landingTopics">
        <TopicSearch></TopicSearch>
      </div>
      <div className="landingCta">
        <CallToAction url="/pricing" buttonText={"SUBSCRIBE"} title="Join us to get premium content" description={"subscribe to get access to a wide range of ready-to-use, engaging lesson plans made in online interactive presentations with teacher keys and a step-by-step to guide you through every moment."}></CallToAction>
      </div>
    </div>
    </>
  );
};

export default Landing;
