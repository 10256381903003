import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import "rsuite-table/dist/css/rsuite-table.css";
import React from 'react'

const UserTable = ({data, onRowClick}) => {

    return (
        <Table onRowClick={onRowClick} autoHeight data={data}>
          <Column resizable>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="_id" />
          </Column>
          <Column resizable>
            <HeaderCell>Title</HeaderCell>
            <Cell dataKey="title" />
          </Column>
          <Column resizable>
            <HeaderCell>Category</HeaderCell>
            <Cell>
            {(rowData) => {
                return rowData.category ? rowData.category : "<no category>";
            }}
            </Cell>
          </Column>
          <Column resizable>
            <HeaderCell>Subscription</HeaderCell>
            <Cell dataKey="suscription" />
          </Column>
          <Column resizable>
            <HeaderCell>Duration</HeaderCell>
            <Cell dataKey="duration" />
          </Column>
          <Column resizable>
            <HeaderCell>Level</HeaderCell>
            <Cell dataKey="level"></Cell>
          </Column>
          <Column resizable>
            <HeaderCell>Update Date</HeaderCell>
            <Cell dataKey="createdAt" />
          </Column>
          <Column width={400} resizable>
            <HeaderCell>Description</HeaderCell>
            <Cell dataKey="description" />
          </Column>
        </Table>
    )
}

export default UserTable;
