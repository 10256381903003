import axios from "axios";
import { apiUrl } from "../../constants/network";
import { getSessionToken } from "../../session/sessionUtis";

function getPricing(callBack) {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: apiUrl + "/pricing/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function addPricing(pricingData, callBack) {
  pricingData.descriptions = pricingData.descriptions.split(";");
  pricingData.isFree = pricingData.price == 0;
  let data = JSON.stringify(pricingData);
  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/pricing",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function deletePricing(id, callBack) {
  var config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: apiUrl + "/pricing/" + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function updatePricing(pricingData, callBack) {
  pricingData.descriptions =
    typeof pricingData.descriptions === "array"
      ? pricingData.descriptions.split(";")
      : pricingData.descriptions;
  pricingData.isFree = pricingData.price == 0;

  var config = {
    method: "put",
    maxBodyLength: Infinity,
    url: apiUrl + "/pricing/" + pricingData._id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
    data: pricingData,
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

export { getPricing, addPricing, deletePricing, updatePricing };
