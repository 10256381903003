import React, { useState } from "react";
import { loginUser } from "../../network/login/login";
import Button from "../common/button";
import Modal from "../common/modal/modal";
import {VscError} from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import Title from "../common/title/title";
import logo from "../../img/svg/logo.svg";
import {AiFillCloseCircle} from 'react-icons/ai';
import bannerImage from "../../img/login.svg";


const LoginModal = ({ isOpen, setIsOpen }) => {
  const [loginData, setLoginData] = useState({});
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();

  const changeLoginData = (value) => {
    const input = value.target.name;
    const inputValue = value.target.value;
    setLoginData((oldProps) => ({ ...oldProps, [input]: inputValue }));
  };

  const goToPricing = () =>{
    setIsOpen(false);
    navigate("/pricing");
  }

  const goToPasswordRecovery = ()=> {
    setIsOpen(false);
    navigate("/password-recovery")
  }

  const submitLogin = () => {
    loginUser(loginData, (response => {

      if(!response || !Object.keys(response).length || response?.response?.status === 400){
        setNotification("Invalid email or password!");
        return
      }

      setIsOpen(false);
      navigate("/");
    }));
  };

  const customStyles = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <Modal customStyles={customStyles} isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="loginModal">

        <div className="loginModalSideBanner">
          <img src={bannerImage}></img>
        </div>

        <div onClick={()=>setIsOpen(false)} className="loginModalMobileClose">
          <AiFillCloseCircle></AiFillCloseCircle>
        </div>


        <div className="loginModalLoginBody">
        <div className="loginBrand">
          <img src={logo}></img>
        </div>
        <div className="loginFields">
          <input
            name="email"
            onChange={(value) => {
              changeLoginData(value);
            }}
            placeholder="Email"
            className="loginModalInput"
            type="text"
          />
          <input
            name="password"
            onChange={(value) => {
              changeLoginData(value);
            }}
            placeholder="Password"
            className="loginModalInput"
            type="password"
          />
        </div>
        <div className="loginSubmit">
          <Button onClick={goToPasswordRecovery} className="link">Forgot password?</Button>
          <Button onClick={submitLogin} type={"black"}>
            LOG IN
          </Button>
        </div>
        <div className="">
          {notification && <Title size="m" weigth="black"><VscError></VscError>{notification}</Title>}
        </div>
        <div className="loginSingUp">
          <Button onClick={goToPricing} className="link">Don't have an account?</Button>
          <div className="">
            <Button onClick={goToPricing} className={"blackBorder"}>Sign up</Button>
          </div>
        </div>
      </div>
        </div>

    </Modal>
  );
};

export default LoginModal;
