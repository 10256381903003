import logo from "../../img/svg/logo.svg";
import "./styles.css";
import {useNavigate} from "react-router-dom";

function Footer() {

  const navigate = useNavigate();

  return (
    <div className="standarSidePadding footer dangerBg">
      <div className="footerData">
        <div className="footerTabItem">
          <div className="footerLogo">
            <img src={logo}></img>
          </div>
        </div>

        <div className="footerItem">
          <div onClick={()=>navigate("/faq")} className="footerTabItem">FAQ</div>
          <div onClick={()=>navigate("/policy")} className="footerTabItem">Privacy & Policy</div>
          <div onClick={()=>navigate("/terms")} className="footerTabItem">Terms and Conditions</div>
        </div>
        <div className="footerItem">
          <div onClick={()=>navigate("/about-us")}  className="footerTabItem">About us</div>
          <div onClick={()=>navigate("/contact")}  className="footerTabItem">Contact us</div>
        </div>
      </div>

      <div className="copyright">&copy; Copyright All Rights Reserved </div>
    </div>
  );
}

export default Footer;
