import React, { useState, useEffect } from 'react';
import {getBilling, getSubscriptionDetails, suspendsuscription, activatesubscription} from "../../../../network/payments/payments";
import './styles.css';
import {useNavigate} from 'react-router-dom';
import Button from '../../../common/button';
import CodeInput from '../../../common/codeInput/CodeInput';

const AccountSubscription = () => {

    const [billing, setBilling] = useState([]);
    const [subscriptionDetail, setSubscriptionDetail] = useState({});
    const navigate = useNavigate();

    const suspend = () => {
        suspendsuscription((res)=>{

        });
    }

    const reactivate = () => {
        activatesubscription((res)=>{

        });
    }

    useEffect(() => {
        getBilling( (response)=>{
            setBilling(()=>response);
        });

        getSubscriptionDetails( (response =>{
            setSubscriptionDetail(response);
        }));

    }, []);

    if(!subscriptionDetail){
        return <div className="noSus">
            <div className="noSusCol">
            Free subscription
                <Button onClick={()=>{navigate('/pricing')}} type="black">Get premium!</Button>
            </div>
                <CodeInput promo={false}></CodeInput>
            </div>
    }

    return (
        <div className="accountSubscription">
            <div className="accountSubscriptionDetails">

                <div className="accountSubscriptionDetailsItem">
                    <div className="asdih">Subscription</div>
                    <div className="">{subscriptionDetail?.pricing_Id?.name}</div>
                    <div className="">${subscriptionDetail?.pricing_Id?.price} / month</div>
                    <div className="">Status: <b>{subscriptionDetail.renewal? "Active" : "Suspended"}</b></div>
                </div>
                <div className="accountSubscriptionDetailsItem">
                    <div className="asdih">{subscriptionDetail.renewal ? "Next payment" :"Your PREMIUM plan will end in"}</div>
                    <div className="">{subscriptionDetail?.finish_at?.split("T")[0]}</div>
                </div>


            </div>
            <div className="accountSubscriptionTable">

                <table>
                <tr className="accountSubscriptionTableHead">
                    <th className="fb">Subscription</th>
                    <th>Status</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Payment Mehod</th>
                    <th>Amount</th>
                </tr>
                {
                    billing.map((el) => (
                        <tr className="accountSubscriptionTableRow">
                            <td>{el.pricing_Id.name}</td>
                            <td>Approved</td>
                            <td>{el.started_at.split("T")[0]}</td>
                            <td>{el.finish_at.split("T")[0]}</td>
                            <td>Paypal</td>
                            <td>${el.pricing_Id.price}</td>
                        </tr>
                    ))
                }
                </table>
            </div>
            <div className="accountSubscriptionFooter">
                <div className="accountSubscriptionFooterItem">
                    <div className="">Have some problems?</div>
                    <div className="accountSubscriptionFooterItemBtns">
                        <Button onClick={()=>{navigate('/faq')}}> CHECK FAQ </Button> or <Button onClick={()=>{navigate('/contact')}}> CONTACT US </Button>
                    </div>
                </div>
                <div className="accountSubscriptionFooterItem">
                    {
                        subscriptionDetail.renewal ? (
                            <Button dialogData="cancel your subscription?" onClick={suspend} type="black">Cancel Subscription</Button>
                        ) : (
                            <Button dialogData="reactivate your subscription?" onClick={reactivate} type="black">Reactivate Subscription</Button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default AccountSubscription
