import Modal from "../../../../../common/modal/modal";
import Title from "../../../../../common/title/title";
import { useState, useEffect } from "react";
import Button from "../../../../../common/button";
import { addPricing } from "../../../../../../network/pricing/pricing";

const AddPricingModal = ({ isOpen, setIsOpen }) => {
  const customStyles = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
  };

  const [isReady, setIsReady] = useState(false);
  const [pricingData, setPricingData] = useState({
    name: null,
    price: null,
    descriptions: null,
  });

  const handleFormChange = (target) => {
    setPricingData((oldData) => ({
      ...oldData,
      [target.target.name]: target.target.value,
    }));
  };

  const submitForm = () => {
    addPricing(pricingData, (response) => {});
  };

  useEffect(() => {
    const missing = Object.values(pricingData).filter((el) => !el || el === "");
    setIsReady(missing.length < 1);
  }, [pricingData]);

  return (
    <Modal customStyles={customStyles} isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="addLessonPlanModal">
        <div className="addLessonPlanModalTitle">
          <Title weigth={"black"} size="l">
            Add Pricing
          </Title>
          <Button dialogData="add this new pricing?" disabled={!isReady} onClick={()=>{submitForm(); setIsOpen(false);}} type="black">
            {" "}
            Submit{" "}
          </Button>
        </div>
        <div className="addLessonPlanForms">
          <div className="addLessonPlanModalBody">
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Name</div>
              <input
                name="name"
                onChange={handleFormChange}
                className="addLessonPlanModalFormGroupInput"
                type="text"
              />
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Price (In dolars)</div>
              <input
                name="price"
                onChange={handleFormChange}
                className="addLessonPlanModalFormGroupInput"
                type="number"
              />
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Descriptions (semicolon separated ; )</div>
              <textarea
                name="descriptions"
                onChange={handleFormChange}
                className="addLessonPlanModalFormGroupInput"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddPricingModal;
