import React from 'react';
import image from '../../../img/noresults.svg'
import './styles.css';

const NoResults = ({isFavorites}) => {
    return (
        <div className="noResults">
            <div className="noResultsText">
                <div className="noResultsTextTitle">{isFavorites ? "No saved" : "No matching"}</div>
                <div className="noResultsTextTitle secondaryText">{isFavorites ? "lessons yet" : "search results"}</div>
                <div className="noResultsTextDesc"> {isFavorites ? "Tip: you can save a lesson with its bookmark button" : "Try using more general search terms"} </div>
                <div className="noResultsTextCTA"></div>
            </div>
            <div className="noResultsImg">
                <img src={image}></img>
            </div>
        </div>
    )
}

export default NoResults
