import React, { useContext } from "react";
import Title from "../title/title";
import { LanguageContext } from "../../../context/languageContex";

import { IoIosBusiness, IoMdBrush } from "react-icons/io";
import { RiHeartPulseFill } from "react-icons/ri";
import { GiBookCover } from "react-icons/gi";
import { MdSportsBasketball } from "react-icons/md";
import {
  FaFeatherAlt,
  FaTree,
  FaCar,
  FaMoneyBillWave,
  FaPaw,
} from "react-icons/fa";
import { SlMagnifier } from "react-icons/sl";
import { BiRestaurant } from "react-icons/bi";
import { CgScreen } from "react-icons/cg";

import "./styles.css";
import { useNavigate } from "react-router-dom";

const TopicSearch = () => {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();

  return (
    <div className="topicSearch">
      <div className="topicSeparator">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fill-opacity="1"
            d="M0,128L80,138.7C160,149,320,171,480,176C640,181,800,171,960,154.7C1120,139,1280,117,1360,106.7L1440,96L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
          ></path>
        </svg>
      </div>
      <div className="topicViewer">
        <div className="topicViewerTitle">
          <Title hasUnderline weigth="black" size="l">
            {" "}
            {language.browseByTopics}{" "}
          </Title>
        </div>
        <div className="topicGrid">
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <IoIosBusiness /> Business
          </div>
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <RiHeartPulseFill /> Health
          </div>
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <GiBookCover /> Culture
          </div>
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <MdSportsBasketball /> Sports
          </div>
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <FaFeatherAlt /> History
          </div>
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <FaTree /> Nature
          </div>
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <SlMagnifier /> True Crime
          </div>
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <FaCar /> Transportation
          </div>
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <FaMoneyBillWave /> Economy
          </div>
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <BiRestaurant /> Food and restaurants
          </div>
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <FaPaw /> Animals and pets
          </div>
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <CgScreen /> Technology and social media
          </div>
          <div
            onClick={() => {
              navigate("/lesson-plans");
            }}
            className="topicGridItem"
          >
            <IoMdBrush /> Art
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicSearch;
