import ReactModal from 'react-modal';
import {AiFillCloseCircle} from 'react-icons/ai'
import React from 'react'
import "./styles.css";

const Modal = ({isOpen, setIsOpen, customStyles, children, shouldCloseOnOverlayClick=true}) => {

    const customStyle = {
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content:{
            ...customStyles,
            borderRadius: "20px",
            padding: 0,
          }
    }

  return (
      <ReactModal
        style={customStyle}
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={()=>setIsOpen(false)}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <div className="modalClose">
          <div onClick={()=>setIsOpen(false)} className="modalCloseButton">
          <AiFillCloseCircle />
          </div>
        </div>
        {children}
      </ReactModal>
  )
}

export default Modal