import Header from './components/header/header';
import Footer from './components/footer/footer';
import LessonPlans from './components/pages/lessons-plans/lesson-plans';
import LandingPage from './components/pages/landing';
import AboutUs from './components/pages/aboutUs/AboutUs';
import Contact from './components/pages/contact/Contact';
import Account from './components/pages/account/Account';
import Payment from './components/pages/payment/Payment';
import Pricing from './components/pages/pricing/pricing';
import Faq from './components/pages/faq/Faq';
import Activation from './components/pages/activation/Activation';
import Terms from './components/pages/terms/Terms';
import { LanguageProvider } from './context/languageContex';
import {
  Routes,
  Route
} from "react-router-dom";
import { ReactSession } from 'react-client-session';
import './App.css';
import Register from './components/pages/register/register';
import Lesson from './components/pages/lesson/lesson';
import PasswordRecover from './components/pages/account/password-recover/PasswordRecover';
import useBreakPoint from './hooks/useBreakPoint';
import { getUserSession, removeUserSession } from "./session/sessionUtis";
import { sessionRefresh } from './network/sessionRefresh/sessionRefresh';
import React, { useState } from 'react';
import ReactGa from "react-ga";


function App() {

  ReactGa.initialize("AW-11328247280")

  const breakpoint = useBreakPoint();
  ReactSession.setStoreType("localStorage");

  const userSession = getUserSession();

  const [searchQuery, setSearchQuery] = useState("");

   if(userSession){
     const actualDate = Date.now() / 1000;

     if(actualDate > userSession.exp){
       removeUserSession();
     } else {
       sessionRefresh();
       userSession.resfeshInterval = setInterval(sessionRefresh, 3200000);
     }
   }

  return (
    <LanguageProvider>
      <div className="App">
        <Header breakpoint={breakpoint} hasSearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery}></Header>
        <Routes>
          <Route path="/" element={<LandingPage breakpoint={breakpoint}/>}></Route>
          <Route path="/lesson-plans" element={<LessonPlans searchQuery={searchQuery}/>}></Route>
          <Route path="/my-lesson-plans" element={<LessonPlans searchQuery={searchQuery} onlyFavorites/>}></Route>
          <Route path="/pricing" element={<Pricing/>}></Route>
          <Route path="/faq" element={<Faq></Faq>}></Route>
          <Route path="/terms" element={<Terms></Terms>}></Route>
          <Route path="/policy" element={<Terms policy></Terms>}></Route>
          <Route path="/activation" element={<Activation></Activation>}></Route>
          <Route path="/payment" element={<Payment/>}></Route>
          <Route path="/about-us" element={<AboutUs/>}></Route>
          <Route path="/contact" element={<Contact></Contact>}></Route>
          <Route path="/register" element={<Register></Register>}></Route>
          <Route path="/account" element={<Account></Account>}></Route>
          <Route path="/lesson/:id" element={<Lesson></Lesson>}></Route>
          <Route path="/password-recovery" element={<PasswordRecover></PasswordRecover>}></Route>
        </Routes>
        <Footer></Footer>
      </div>
    </LanguageProvider>
  );
}

export default App;
