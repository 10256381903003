import {useEffect} from 'react'
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import {paypal_client_id} from "../../constants/payment";

const ButtonWrapper = ({ type, onApprove, planId }) => {
	const [{ options }, dispatch] = usePayPalScriptReducer();

	useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, [type]);

	return (<PayPalButtons
		createSubscription={(data, actions) => {
			return actions.subscription
				.create({
					plan_id: planId,
				})
				.then((orderId) => {
					return orderId;
				});
		}}
        onApprove={data => onApprove(data)}
		style={{
			label: "subscribe",
		}}
	/>);
}

export default function PayPalButtonsComponent({onApprove, planId}) {
	return (
		<PayPalScriptProvider
			options={{
				"client-id": paypal_client_id,
				components: "buttons",
				intent: "subscription",
				vault: true,
                "disable-funding":"credit",
			}}
		>
			<ButtonWrapper onApprove={onApprove} planId={planId} type="subscription" />
		</PayPalScriptProvider>
	);
}