import axios from "axios";
import { apiUrl } from "../../constants/network";
import { getSessionToken, getUserSession, refreshUser } from "../../session/sessionUtis";

function addUser(userData, callBack) {
  let data = JSON.stringify(userData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.newUser);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function validateUserEmail(userData, callBack) {

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/verify",
    headers: {
      "Content-Type": "application/json",
    },
    data: userData,
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function resendConfirmationCode(email, callBack) {

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/resendconfirmationcode",
    headers: {
      "Content-Type": "application/json",
    },
    data: {email},
  };

  axios(config)
    .then(function (response) {
      callBack(response);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function getUserDb(callBack) {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/"+getUserSession().user._id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function getUsers(callBack) {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function changePassword(data, callback) {
  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/changepassword/",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
    data: {
      ...data,
      accessToken: getSessionToken(),
      email: getUserSession().email,
    },
  };

  axios(config)
    .then(function (response) {
      callback(response.data.data);
    })
    .catch(function (error) {
      callback(error);
    });
}

function addLesson(lessonId, callBack) {
  let data = JSON.stringify({ courseId: lessonId });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/addcourse/" + getUserSession()?.user._id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      callBack(response);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function updateUser(newData, callBack) {

  const user = getUserSession().user;
  let data = JSON.stringify({...user, ...newData});

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/" + user?._id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      refreshUser();
      callBack(response);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function removeLesson(lessonId, callBack){
  let data = JSON.stringify({ courseId: lessonId });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/removecourse/" + getUserSession()?.user._id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      callBack(response);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function recoverPassword(email, callBack) {
  let data = JSON.stringify({ email: email });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/forgotpassword/",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      callBack(response);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function confirmRecoverPassword(data, callBack) {
  let fdata = JSON.stringify(data);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/confirmforgotpassword/",
    headers: {
      "Content-Type": "application/json",
    },
    data: fdata,
  };

  axios(config)
    .then(function (response) {
      callBack(response);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function enrollUser(token, callBack){
  const userId = getUserSession();
  let data = JSON.stringify({ token: token, user_Id: userId.user._id});

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/enroll",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function changeUserPicture(data, callBack) {

  const FormData = require('form-data');
  let form = new FormData();

  form.append('userImage', data.fileInput.files[0] , data.userImage);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/addPhoto/" + getUserSession()?.user._id,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getSessionToken()}`,
    },
    data: form,
  };

  axios(config)
    .then(function (response) {
      callBack(response);
    })
    .catch(function (error) {
      callBack(error);
    });
}

export {
  addUser,
  validateUserEmail,
  resendConfirmationCode,
  getUsers,
  changePassword,
  addLesson,
  recoverPassword,
  confirmRecoverPassword,
  removeLesson,
  enrollUser,
  getUserDb,
  updateUser,
  changeUserPicture,
};
