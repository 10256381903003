import React, { useState } from "react";
import Form from "../../common/form/form";

const AccountDetailsForm = ({ setData }) => {

  const accountDetailsFields = [
    {
      label: "Current password",
      isRequire: true,
      type: "password",
      actualValue: null,
      valueName: "previousPassword",
    },
    {
      label: "New password",
      isRequire: true,
      type: "password",
      actualValue: null,
      valueName: "ProposedPassword",
      sameAsNext: true,
    },
    {
      label: "Confirm new password",
      isRequire: true,
      actualValue: null,
      type: "password",
      valueName: "ProposedPasswordComfirmation",
    },
  ];

  const [accountDetailsForm, setAccountDetailsForm] = useState(accountDetailsFields);

  return (
    <Form dialogData="change your password?" dialogData={"change your password?"} title={""} noBorder buttonText={"SAVE CHANGES"} formFields={accountDetailsForm}  setFormFields={setAccountDetailsForm}  setData={setData}></Form>
  );
};

export default AccountDetailsForm;
