import { useState } from "react";
import Dialog from "../common/dialog/dialog";
import "./styles.css";

function Button({ type, className, children, onClick, disabled = false, dialogData, hover }) {
  let clas = "button";

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  if (type === "highLighted") {
    clas = "highLighted button";
  }

  if (type === "black") {
    clas = "blackButton button";
  }

  if (type === "danger") {
    clas = "dangerButton button";
  }

  if (type === "cta") {
    clas = "ctaButton button";
  }

  if (disabled) {
    clas = "disabled button";
  }

  if (hover){
    clas += ' bho'
  }

  const clickHandler = () => {
    if (disabled) return;

    if (dialogData){
      setDialogIsOpen(true);
      return;
    }

    onClick();
  }

  return (
    <>
    <div
      onClick={clickHandler}
      className={clas + " " + className}
    >
      {children}
    </div>
    {dialogData && <Dialog setIsOpen={setDialogIsOpen} label={dialogData} onConfirm={onClick} onCancel={()=>setDialogIsOpen(false)} isOpen={dialogIsOpen}></Dialog>}
    </>
  );
}

export default Button;
