import React from 'react';
import Star from '../../../img/svg/star.svg';
import Button from '../../common/button';
import CollapsableText from '../../common/collapsableText/CollapsableText';
import Title from '../../common/title/title';
import {useNavigate} from 'react-router-dom'
import './styles.css';

const Faq = () => {

    const navigate = useNavigate();

    const paymentFaq = [
        {
            question: "How can I pay?",
            answer: "You can use credit or debit card, Paypal payments are also accepted. Credit card payments are secured by Paypal ",
        },
        {
            question: "When will I be charged for my subscription?",
            answer: "You will be charged at the moment you subscribe, and every month you will be automatically charged the same day of your first payment.",
        },
        {
            question: "In what currency can I pay for my subscription?",
            answer: "Our default currency is US Dollars.",
        },
    ]

    const subscriptionFaq = [
        {
            question: "How can I see the details of my subscription?",
            answer: " Go to your profile at the top right of the home page, there, in the subscription tab you’ll find the details.",
        },
        {
            question: "How can I change my subscription?",
            answer: "Go to your profile at the top right of the home page, there, in the subscription tab you’ll find the button to change your subscription",
        },
        {
            question: "How can I cancel my subscription?",
            answer: "Go to your profile at the top right of the home page, there, in the subscription tab you’ll find the button to change your subscription.",
        },
        {
            question: "Is there another type of subscription for groups larger than 5 persons or corporates?",
            answer: "We can offer you different options that fit your needs, please contact us with your individual case and we’ll get to you. ",
        },
    ]

    const lessonFaq = [
        {
            question: "How can I find a lesson plan?",
            answer: "In the home page you’ll find a topic-based search, or you can go to the lesson plans tab in the top menu, where you can filter your search by level, topic, grammar, or type of subscription. We also have a search box where you can type key words and we’ll show you the lesson plans related to those words!",
        },
        {
            question: "Where can I see my saved lesson plans?",
            answer: "In the top menu, you’ll find at the right a bookmark icon, there you can find your saved lessons and modify them if you don’t want them saved anymore.",
        },
        {
            question: "How do I use a lesson plan?",
            answer: "Each lesson plan has a step-by-step of how to use it. It is important that you know that each lesson plan is an interactive presentation. On the upper left corner of each slide you will find a button with teacher instructions and answer keys with this icon <Icon> in case there are any listening or reading comprehension questions.",
        },
        {
            question: "Can I download a lesson plan?",
            answer: "Unfortunately, it is not possible to download lesson plans yet. However, you can access them at any time online making it perfect for your online classes or anywhere you have internet connection!",
        },
        {
            question: "Can I get transcripts for the videos?",
            answer: "We do not provide the transcripts of videos but, because we strive for practicality, all videos range from 1-7 minutes and we provide answer keys to questions related to the video.",
        },
        {
            question: "Can I send the lesson plan to my student?",
            answer: "Yes! We encourage sharing the lesson plan link with your students for them to interact with it directly. ",
        },
        {
            question: "Are your lesson plans for groups or private classes?",
            answer: "Our lesson plans are carefully designed to be used in both private classes and group classes. However, the estimated time for each activity might increase if you are teaching groups. ",
        },
        {
            question: "Are the lesson plans aligned with any specific educational standards or curriculum?",
            answer: "Our lesson plans follow the Task Based Approach methodology and we have a meticulously designed curriculum that is being constantly updated. ",
        },
        {
            question: "How often do you release new lesson plans?",
            answer: "You will find 3 new lesson plans per week on our website! ",
        },
        {
            question: "Can the lesson plans be customized?",
            answer: "Unfortunately, our presentations cannot be customized, but they are easy to adapt to your own teaching style! Feel free to skip any steps or add any in class without needing to modify the interactive presentation.",
        },
        {
            question: "Is there a process for suggesting topics for future lesson plans?",
            answer: "If you have any future topic suggestions, you can get in touch with us through weplanesl@gmail.com",
        },
    ]

    return (
        <div className="faqPage">
            <div className="faqPageBanner">
                <div className="faqPageBannerImg">
                    <img src={Star}></img>
                </div>
                <div className="faqPageBannerText">
                    <div className="faqPageBannerTextTitle">
                        <Title light hasUnderline size="m" weigth="black">FAQ</Title>
                    </div>
                    <div className="faqPageBannerTextSubtitle">
                    Find here some information to answer the most common doubts about our payment methods, subscription plans and lesson plans, if you have any other questions
                    </div>
                    <div className="faqPageBannerTextButton">
                        <Button  onClick={()=>{navigate('/contact')}} type="cta">Contact us!</Button>
                    </div>
                </div>
                <div className="faqPageBannerImg">
                    <img src={Star}></img>
                </div>
            </div>
            <div className="faqPageContent standarSidePadding">

                <div className="faqPageContentColumn">
                    <div className="faqPageContentColumnTitle">Payment</div>
                    <div className="faqPageContentColumnQuestions">

                        {paymentFaq.map((el) => (
                            <CollapsableText text={el.question} collapsedText={el.answer}></CollapsableText>
                        ))}
                    </div>
                </div>
                <div className="faqPageContentColumn">
                    <div className="faqPageContentColumnTitle">Account and subscriptions</div>
                    <div className="faqPageContentColumnQuestions">
                    {subscriptionFaq.map((el) => (
                            <CollapsableText text={el.question} collapsedText={el.answer}></CollapsableText>
                        ))}
                    </div>
                </div>
                <div className="faqPageContentColumn">
                    <div className="faqPageContentColumnTitle">Lesson plans</div>
                    <div className="faqPageContentColumnQuestions">
                    {lessonFaq.map((el) => (
                            <CollapsableText text={el.question} collapsedText={el.answer}></CollapsableText>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Faq
