import { SpinnerDotted } from "spinners-react";
import "./styles.css";

const Spinner = ({ props }) => {
    return (
        <div className="spinner">
          <SpinnerDotted
            size={40}
            thickness={180}
            speed={100}
            color="#1E1856"
            {...props}
          />
        </div>
    );
  }

  export default Spinner;