import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import "rsuite-table/dist/css/rsuite-table.css";
import React from 'react'

const CharacteristicsTable = ({data, onRowClick}) => {

    return (
        <Table onRowClick={onRowClick} autoHeight data={data}>
          <Column width={400} resizable>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="_id" />
          </Column>
          <Column width={400} resizable>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>
          <Column resizable>
            <HeaderCell>Update Date</HeaderCell>
            <Cell dataKey="createdAt" />
          </Column>
        </Table>
    )
}

export default CharacteristicsTable;
