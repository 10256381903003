import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Card from '../card';
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";

const index = ({items}) => {

    const responsive = {
      0: { items: 1 },
      568: { items: 2 },
      1024: { items: 3 },
      1210: { items: 3 },
      1570: {items: 3},
     };

     const controlStyle={
         fontSize: "2rem",
         color: "black"
     }

     const formattedItems = items.map( item => (
        <Card isCarousel data={item} />
     ))


    return (
        <div className="carousel">
            <AliceCarousel
                mouseTracking
                disableDotsControls
                items={formattedItems}
                responsive={responsive}
                autoWidth
                controlsStrategy="alternate"
                renderPrevButton = { (isDisabled) => (<TfiArrowCircleLeft className="pc" style={controlStyle}/>)}
                renderNextButton = { (isDisabled) => (<TfiArrowCircleRight className="pc" style={controlStyle}/>)}
            />
        </div>
    )
}

export default index
