import axios from "axios";
import { apiUrl } from "../../constants/network";
import { getSessionToken, getUserSession } from "../../session/sessionUtis";

function getCourse(id, callBack) {

  const session = getUserSession()?.user || null;

  var config = {}

  // SIN SESION LLEGA: "Not available"
  // CON SESION PREMUN LLEGA URL
  // CON SESION Basic "Not available for your subscription"

  if(!session){

    config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiUrl + "/course/"+id+"/",
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if(session){
    config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiUrl + "/course/" +id+"/userType/"+session.userType,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getSessionToken()}`,
      },
    };
  }

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function getCourses(callBack) {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: apiUrl + "/course/courses/50",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
  };

  axios(config)
    .then(function (response) {
      const res = response.data.data;
      res.map(element => {
        element.category = element.category.map(el => el.name).join("/");
        element.level = element.level.map(el => el.name).join("/");
        element.grammar = element.grammar.map(el => el.name).join("/");
        return element;
      })
      callBack(res);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function getCoursesWithFilter(filter, callBack) {

  const query = filter.map(el => el.cat+"="+el.id).join("&");

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: apiUrl + "/course?"+query,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
  };

  axios(config)
    .then(function (response) {
      const res = response.data.data;
      res.map(element => {
        element.category = element.category.map(el => el.name).join("/");
        element.level = element.level.map(el => el.name).join("/");
        element.grammar = element.grammar.map(el => el.name).join("/");
        return element;
      })
      callBack(res);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function getCoursesWithText(text, callBack) {

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: apiUrl + "/course?text="+text,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
  };

  axios(config)
    .then(function (response) {
      const res = response.data.data;
      res.map(element => {
        element.category = element.category.map(el => el.name).join("/");
        element.level = element.level.map(el => el.name).join("/");
        element.grammar = element.grammar.map(el => el.name).join("/");
        return element;
      });
      callBack(res);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function addCourse(lessonPlanData, callBack){

  const FormData = require('form-data');
  let data = new FormData();

  lessonPlanData.level.forEach(el => {
    data.append('level', el);
  });

  lessonPlanData.grammar.forEach(el => {
    data.append('grammar', el);
  });

  lessonPlanData.category.forEach(el =>{
    data.append('category', el);
  });

  data.append('description', lessonPlanData.description);
  data.append('duration', lessonPlanData.duration);
  data.append('urlLesson', lessonPlanData.urlLesson);
  data.append('suscription', lessonPlanData.suscription);
  data.append('warmUp', lessonPlanData.warmUp);
  data.append('task', lessonPlanData.task);
  data.append('vocabulary', lessonPlanData.vocabulary);
  data.append('input', lessonPlanData.input);
  data.append('feedback', lessonPlanData.feedback);
  data.append('lessonImage', lessonPlanData.fileInput.files[0] ,lessonPlanData.lessonImage);
  data.append('title', lessonPlanData.title);

  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/course/",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getSessionToken()}`,
    },
    data:data,
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function deleteCourse(id, callBack){
  var config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: apiUrl + "/course/"+id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function updateCourse(data, callBack){
  var config = {
    method: "put",
    maxBodyLength: Infinity,
    url: apiUrl + "/course/"+data._id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
    data:data,
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

export { getCoursesWithFilter, getCourses, addCourse, getCourse, deleteCourse, updateCourse, getCoursesWithText }