import axios from "axios";
import { apiUrl } from "../../constants/network";

function getTags(callBack) {
  const endpoints = ["level", "grammar", "characteristic"];
  const res = {level:[], grammar:[], characteristic:[]};

  endpoints.forEach((ep) => {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiUrl + `/${ep}/`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
       res[ep] = response.data?.data?.map(el => ({label:el.name, value:el._id}));
      })
      .catch(function (error) {
        res[ep] = error;
      });
  });

  callBack(res);
}

export { getTags };
