import { useState, useEffect, useRef } from "react";
import {FaRoute, FaSearch} from "react-icons/fa"
import './styles.css';
import { useNavigate } from "react-router-dom";
import { getCoursesWithText } from '../../../network/course/course';

function SearchInput({bordered, placeHolder, setSearchQuery, searchQuery}) {

    const navigate = useNavigate();
    const inpRef = useRef();
    const [lessons, setLessons] = useState([]);

    const search = () => {
        setSearchQuery(searchQuery)
        navigate("/lesson-plans?q="+searchQuery);
    }

    const enterHandler = (event) => {
        if(event.key === 'Enter'){
            setSearchQuery(searchQuery)
            navigate("/lesson-plans?q="+searchQuery);
        }
    }

    useEffect(() => {
        getCoursesWithText(searchQuery, (response)=>{
            setLessons(()=>response);
        });

    }, [searchQuery])

    return (
        <div className="searcherCon">
        <div className='searcher'>
            <input onKeyDown={enterHandler} ref={inpRef} value={searchQuery} onChange={(input)=>{setSearchQuery(input.target.value)}} className={bordered?'searchInputBorder':"searchInput"} type="text" placeholder={placeHolder}/>
            <button onClick={search} className='buttonContainer'>
                <FaSearch></FaSearch>&nbsp;
                <span className='searchLabel'>Search</span>
            </button>
        </div>

        { (searchQuery != "" && document.activeElement === inpRef.current) &&
        <div className="searchResults">
            {lessons.map(el => (
                <div
                    className="searchResultsItem"
                    onClick={()=>{navigate("/lesson/"+el._id); setSearchQuery("")}}
                >{el.title}</div>
            ))}
        </div>}
        </div>
    )
}


export default SearchInput;



