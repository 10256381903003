import React from 'react'
import Button from '../../common/button';
import Title from '../../common/title/title'
import OrderForm from '../../forms/orderForm/orderForm';
import {useNavigate, useLocation} from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import "./styles.css";

const Activation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const planData = location.state?.planData;

    const today = new Date();
    if(!planData){
        navigate("/pricing");
        return;
    }

    return (
        <div className="activationPage standarSidePadding">
            <div className="activationDetails">
                <div className="activationDetailsTitle">
                    <Title hasUnderline size="l" weigth="black">
                        Order received!
                    </Title>
                </div>
                <div className="activationDetailsSubtitle">
                    <AiOutlineCheckCircle/> Your account has been activated!
                </div>
                <div className="activationDetailsDesc">
                Check your inbox for our confirmation e-mail and begin to use our lesson plans!
                </div>
                <div className="activationDetailsNumber">
                    <div className="activationDetailsNumberTitle">
                        ORDER NUMBER
                    </div>
                    <div>
                        0000{Math.round(Math.random()*10000)}
                    </div>
                </div>
                <div className="activationDetailsNumber">
                    <div className="activationDetailsNumberTitle">
                        DATE
                    </div>
                    <div>
                        {today.toLocaleDateString("en-US")}
                    </div>
                </div>
                <div className="activationDetailsButtons">
                    <Button onClick={()=>navigate("/")} type="black">Return to home page</Button>
                    <Button onClick={()=>navigate("/account")} type="cta" >Go to your account profile</Button>
                </div>
            </div>
            <div className="activationForm">
                <OrderForm data={planData}></OrderForm>
            </div>
        </div>
    )
}

export default Activation
