import { SpinnerDotted } from "spinners-react";
import { validateUserEmail, resendConfirmationCode } from "../../../network/user/user";
import Button from "../../common/button";
import Modal from "../../common/modal/modal";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "./styles.css";
import Title from "../../common/title/title";
import ConfirmationInput from "../../common/confirmationInput";
import Countdown from 'react-countdown';
import { loginUser } from "../../../network/login/login";

const EmailValidationModal = ({
  error,
  isLoading,
  isOpen,
  setIsOpen,
  userData,
}) => {
  const [code, setCode] = useState(null);
  const [notification, setNotification] = useState(null);
  const [timeToWait, setTimeToWait] = useState(Date.now() + 54000)

  const navigate = useNavigate();
  const customStyles = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  };

  const resendCode = () => {
    setTimeToWait(Date.now() + 36000);
    resendConfirmationCode(userData.email, (response)=>{})
  }

  const counterBtn = ({seconds, completed}) => {

    return <>
        <Button disabled={!completed} onClick={resendCode} type={"black"}>
          Resend ({seconds})
        </Button>
      </>
  }

  const validateUser = () => {
    const dataToValidate = {
      email: userData.email,
      code: code,
    };
    validateUserEmail(dataToValidate, (response) => {

      if(response.statusCode !== 200){
        setNotification("Code error, please try again");
        return;
      }

      loginUser({email:userData.email, password:userData.password}, (response) =>{
        if(userData.selectedPlan.isFree){
          navigate('/account');
          setIsOpen(false);
          return;
        } else {
          navigate('/payment', {state:{ planData: userData.selectedPlan }});
          setIsOpen(false);
          return;
        }
      });
    });
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      customStyles={customStyles}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="emailValidationModal">
        {isLoading ? (
          <div className="modalSpinner">
            <SpinnerDotted
              size={70}
              thickness={180}
              speed={100}
              color="#36ad47"
            />
          </div>
        ) : (
          <>
            {error ? (
              <div className="emailValidationModalErrorMessage">
                <Title weigth="black" size="l">
                  Error
                </Title>
                <div className="emailValidationModalErrorSubMessage">
                  {error}
                </div>
                <Button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  type={"black"}
                >
                  Close
                </Button>
              </div>
            ) : (
              <div className="emailValidationModalMessage">
                <Title weigth="black" size="l">
                  Success!
                </Title>
                <div className="emailValidationModalSubMessage">
                  We sent you a confirmation email, please type the confirmation
                  code below
                </div>
                <ConfirmationInput
                  onChange={(event) => {
                    setCode(event.target.value);
                  }}
                ></ConfirmationInput>
                {notification && <div className="divSpaced">{notification}</div>}

                <div className="confirmationModalBtns">
                  <Button onClick={validateUser} type={"black"}>
                    Confirm
                  </Button>
                  <Countdown
                    date ={timeToWait}
                    renderer={counterBtn}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
export default EmailValidationModal;
