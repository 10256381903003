import React, { useState } from 'react'
import Form from '../../common/form/form'

const RecoverPasswordForm = ({setData}) => {

    const forgotPasswordFormData = [
        {
            label:"Verification code",
            isRequire:true,
            type:"text",
            valueName:"code",
        },
        {
            label:"New password",
            isRequire:true,
            type:"password",
            valueName:"password",
            sameAsNext: true,
        },
        {
            label:"Confirm password",
            isRequire:true,
            type:"password",
            valueName:"passwordConfirmation",
        }
    ];

    const [forgotPasswordData, setForgotPasswordData ] = useState(forgotPasswordFormData);


    return (
        <Form title={""} noBorder buttonText={"CHANGE PASSWORD"} formFields={forgotPasswordData}  setFormFields={setForgotPasswordData} setData={setData}></Form>
    )
}

export default RecoverPasswordForm
