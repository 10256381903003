import './styles.css';

const Title = ({size, hasUnderline, weigth, children, light, color}) => {

    const sizes = { s:"s",m:"m",l:"l" }
    const weigths = {black:"black", medium:"medium", ligth:"ligth"}


    return (
        <div className={`${color==="white"? "whiwhi" : ""} ${sizes[size]} ${weigths[weigth]} ${hasUnderline &&  (light ? "textLinedB" : "textLined") } ${light && "ligthTitle"}`}>
            {children}
        </div>
    )
}

export default Title
