import React from 'react'
import Star from "../../../img/svg/star.svg";
import "./styles.css";

const Checkbox = ({text, isActive, onClick}) => {
    return (
        <div className="checkbox" onClick={onClick}>
            <div className="checkboxInput">
                {isActive &&  <div className="checkboxInputBullet"><img src={Star} /></div>}
            </div>
            <div className="checkboxText">{text}</div>
        </div>
    )
}

export default Checkbox;
