import { FaUser } from "react-icons/fa";
import { BiUser, BiFile, BiBookmark, BiLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { ReactSession } from "react-client-session";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { getUserSession } from "../../session/sessionUtis";
import "./styles.css";
import { useState } from "react";

const AccouuntHeaderMenu = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const userImage = getUserSession().user?.userImage || null;

  const onLogOutClick = () => {
    ReactSession.set("session", null);
    ReactSession.set("user", null);
    navigate("/");
  };

  const navigate = useNavigate();
  const onUrlClick = (url) => {
    navigate(url);
  };

  return (
    <div className="headerItem">
      <div
        className="tabItem tabItemBig"
        onClick={() => onUrlClick("/my-lesson-plans")}
      >
        <BiBookmark></BiBookmark>
      </div>
      <div
        className="tabItem tabItemBig accountHeaderMenu"
        onClick={() => {
          setIsUserMenuOpen(!isUserMenuOpen);
        }}
      >
          {userImage? <img src={userImage}></img> : <FaUser></FaUser>}
        <div
          className={
            "accountHeaderMenuList " + (isUserMenuOpen ? "open" : "close")
          }
        >
          <div
            onClick={() => onUrlClick("/account")}
            className="accountHeaderMenuListItem"
          >
            <BiUser></BiUser> Account
          </div>
          <div  onClick={() => onUrlClick("/account")} className="accountHeaderMenuListItem">
            <BiFile></BiFile> Subscription
          </div>
          <div onClick={()=>navigate("/my-lesson-plans")} className="accountHeaderMenuListItem">
            <BiBookmark></BiBookmark> Saved Lessons
          </div>
          <div onClick={()=>navigate("/faq")} className="accountHeaderMenuListItem">
            {" "}
            <AiOutlineQuestionCircle></AiOutlineQuestionCircle> FAQ
          </div>
          <div
            onClick={() => onLogOutClick()}
            className="accountHeaderMenuListItem"
          >
            <BiLogOut /> Log Out
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccouuntHeaderMenu;
