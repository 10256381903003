import React, { useState, useEffect } from 'react';
import './styles.css';
import {useNavigate} from 'react-router-dom';
import {getUserSession} from '../../../../session/sessionUtis';
import Button from '../../../common/button';

const AccountCodes = () => {

    const [codes, setCodes] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        setCodes(getUserSession().user.group);
    },[]);

    return (
        <div className="accountSubscription">
            <div className="accountSubscriptionDetails">
                <div className="accountSubscriptionDetailsItem">
                    <div className="asdih">Codes: </div>
                </div>
            </div>
            <div className="accountSubscriptionTable">

                <table>
                <tr className="accountSubscriptionTableHead">
                    <th>Status</th>
                    <th>Token</th>
                    <th>Description</th>
                </tr>

                <tr className="accountSubscriptionTableRow">
                            <td>Unavailable</td>
                            <td>******</td>
                            <td>This token has been used by you!</td>
                        </tr>

                {
                    codes?.tokens?.map((el) => (
                        <tr className="accountSubscriptionTableRow">
                            <td>{!el.user_id? "Available" : "Unavailable" }</td>
                            <td><b>{el.token}</b></td>
                            <td>{!el.user_id? "You can share this token with a friend!" :`This token is already used! by ${el.user_id.email}`}</td>
                        </tr>
                    ))
                }
                </table>
            </div>
            <div className="accountSubscriptionFooter">
                <div className="accountSubscriptionFooterItem">
                    <div className="">Have some problems?</div>
                    <div className="accountSubscriptionFooterItemBtns">
                        <Button onClick={()=>{navigate('/faq')}}> CHECK FAQ </Button> or <Button onClick={()=>{navigate('/contact')}}> CONTACT US </Button>
                    </div>
                </div>
                <div className="accountSubscriptionFooterItem">
                    <Button dialogData="cancel subscription?"  type="black">Cancel Subscription</Button>
                </div>
            </div>
        </div>
    )
}

export default AccountCodes;
