import React, { useState } from "react";
import Form from "../../common/form/form";
import { ReactSession } from "react-client-session";

const AccountDetailsForm = ({ setData }) => {

  const userData = ReactSession.get("session")?.user;
  const accountDetailsFields = [
    {
      label: "First name",
      placeholder: userData.firstName,
      isRequire: true,
      type: "text",
      actualValue: null,
      valueName: "firstName",
      sibbling: {
        label: "Last name",
        placeholder: userData.lastName,
        isRequire: true,
        type: "text",
        actualValue: null,
        valueName: "lastName",
      },
    },
    {
      label: "Nick name",
      placeholder: userData.username,
      isRequire: true,
      type: "text",
      actualValue: null,
      valueName: "username",
    },
  ];

  const [accountDetailsForm, setAccountDetailsForm] = useState(accountDetailsFields);


  return (
    <Form dialogData={"update your data?"} title={""} noBorder buttonText={"SAVE CHANGES"} formFields={accountDetailsForm}  setFormFields={setAccountDetailsForm} setData={setData}></Form>
  );
};

export default AccountDetailsForm;
