import Button from "../common/button";
import SearchInput from "../common/search input/searchInput";
import HeaderBurger from "./HeaderBurger";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ReactSession } from "react-client-session";
import LoginModal from "../modals/loginModal";
import Logo from "../../img/svg/logo.svg";
import AccouuntHeaderMenu from "./accouuntHeaderMenu";

import "./styles.css";
import { getPricing } from "../../network/pricing/pricing";
import Spinner from "../common/spinner/Spinner";

function Header({ hasSearchBar, breakpoint, setSearchQuery, searchQuery }) {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const isMobile = breakpoint === "mobile";

  const navigate = useNavigate();
  const onUrlClick = (url) => {
    navigate(url);
  };

  const onSignUp = () => {
    setSignUpLoading(true);
    navigate("/pricing");
    setSignUpLoading(false);
    /*getPricing((response) => {
      const freeOne = response.filter((el) => el.name === "BASIC")[0];
    }); */
  };

  const userSession = ReactSession.get("session");

  return (
    <div className="header standarSidePadding ligthGrayBg">
      <LoginModal
        setIsOpen={setIsLoginModalOpen}
        isOpen={isLoginModalOpen}
      ></LoginModal>
      <div className="headerItemContainer">
        <div
          onClick={() => {
            onUrlClick("/");
          }}
          className="headerItem"
        >
          <img src={Logo}></img>
        </div>

        {!isMobile && (
          <div className="headerItem headerFont g20h">
            <div
              onClick={() => {
                onUrlClick("/lesson-plans");
              }}
              className="tabItem"
            >
              LESSON PLANS
            </div>

            <div
              onClick={() => {
                onUrlClick("/pricing");
              }}
              className="tabItem"
            >
              PRICING
            </div>

            <div
              onClick={() => {
                onUrlClick("/about-us");
              }}
              className="tabItem"
            >
              ABOUT US
            </div>
            <div
              onClick={() => {
                onUrlClick("/contact");
              }}
              className="tabItem"
            >
              CONTACT
            </div>
          </div>
        )}

        {!isMobile ? (
          <>
            {userSession ? (
              <AccouuntHeaderMenu></AccouuntHeaderMenu>
            ) : (
              <>
                <div className="headerItem esp">
                  {signUpLoading ? (
                    <Spinner size={20}></Spinner>
                  ) : (
                    <Button onClick={onSignUp}>
                      <span style={{color:"#3C35D8"}}>SIGN UP</span>
                    </Button>
                  )}

                  <Button
                    type={"highLighted"}
                    onClick={() => {
                      setIsLoginModalOpen(!isLoginModalOpen);
                    }}
                  >
                    LOG IN
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <HeaderBurger onSign={onSignUp} onLoginClick={()=>{setIsLoginModalOpen(true)}} hasSession={!!userSession} />
        )}
      </div>
      {hasSearchBar && (
        <div className="inputContainer">
          <SearchInput setSearchQuery={setSearchQuery} searchQuery={searchQuery}></SearchInput>
        </div>
      )}
    </div>
  );
}

export default Header;
