import React, { useState } from 'react';
import { AiOutlinePlusCircle, AiFillMinusCircle } from "react-icons/ai";
import './styles.css';

const CollapsableText = ({text, collapsedText}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);

    return (
        <div className={"collapsableText"} onClick={()=>setIsCollapsed(!isCollapsed)}>
            <div className="collapsableTextIcon">
                {isCollapsed ? <AiOutlinePlusCircle/> : <AiFillMinusCircle/>}
            </div>
            <div className="collapsableTextBody">
                <div className="collapsableTextBodyText">
                    {text}
                </div>
                <div className={`collapsableTextBodyCollapsable ${isCollapsed? "collapsed" : ""}`}>
                    {collapsedText}
                </div>
            </div>
        </div>
    )
}

export default CollapsableText
