import Card from "../../common/card";
import Button from "../../common/button";
import Checkbox from "../../common/checkbox/Checkbox";
import "./styles.css";
import {
  getCourses,
  getCoursesWithFilter,
} from "../../../network/course/course";
import { useState, useEffect } from "react";
import { getQuery, getUserSession, setQuery } from "../../../session/sessionUtis";
import { getTags } from "../../../network/summary/tagSummary";
import Title from "../../common/title/title";
import NoResults from "../../common/noResults/NoResults";

function LessonPlans({ onlyFavorites, searchQuery }) {
  const pricingTags = {
    suscription: [
      { label: "Free", value: "free" },
      { label: "Premium", value: "premium" },
    ],
  };
  const [lessonData, setLessonData] = useState([]);
  const [tags, setTags] = useState();
  const [filters, setFilters] = useState([]);
  const [susFilters, setSusFilters] = useState([]);

  const userFavorites = getUserSession()?.user.course || [];

  useEffect(() => {
    getCourses((response) => {
       if(searchQuery || searchQuery!== ""){
        const filerRes = response?.filter(el=>(
          el.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
          el.title.toLowerCase().includes(searchQuery.toLowerCase())
        ));
        setLessonData(filerRes);

        return;
      }
      setLessonData(response);
    });
  }, [searchQuery]);

  useEffect(()=>{
    getTags((response) => {
      setTags(response);
    });
  },[]);

  useEffect(() => {
    getCoursesWithFilter([...filters, ...susFilters], (response) => {
      if(searchQuery || searchQuery!== ""){
        const filerRes = response?.filter(el=>(
          el.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
          el.title.toLowerCase().includes(searchQuery.toLowerCase())
        ));
        setLessonData(filerRes);

        return;
      }
      setLessonData(response);
    });
  }, [filters, susFilters, searchQuery]);

  const clearAllFilters = () => {
    setFilters([]);
    setSusFilters([]);
  };

  const handleCheckClick = (cat, id) => {
    if (isSelected(id)) {
      setFilters((old) => old.filter((el) => el.id !== id));
      return;
    }
    setFilters((old) => [...old, { cat: cat, id: id }]);
  };

  const isSelected = (id) => {
    return filters.filter((el) => el.id === id).length > 0;
  };

  const isSusSelected = (id) => {
    return susFilters.filter((el) => el.id === id).length > 0;
  };

  const handleSusClick = (cat, id) => {
    if (isSusSelected(id)) {
      setSusFilters((old) => old.filter((el) => el.id !== id));
      return;
    }
    setSusFilters((old) => [...old, { cat: cat, id: id }]);
  };

  return (
    <div className="lessonPlans standarSidePadding">
      <div className="lessonPlansPageTitle">
        <div className="secondaryText">
          What class do you want to{" "}
          <span className="primaryText"> teach today? </span>
        </div>
      </div>

      {
        !onlyFavorites &&
      <div className="completedFilterGroup">
        <div className="filterGroup">
          <ul>
            <h2 className="h2b">LEVEL</h2>
            {tags?.level?.map((el) => (
              <Checkbox
                onClick={() => {
                  handleCheckClick("level", el.value);
                }}
                text={el.label}
                isActive={isSelected(el.value)}
              />
            ))}
          </ul>

          <ul>
            <h2 className="h2b">CATEGORY</h2>
            {tags?.characteristic?.map((el) => (
              <Checkbox
                onClick={() => {
                  handleCheckClick("category", el.value);
                }}
                text={el.label}
                isActive={isSelected(el.value)}
              />
            ))}
          </ul>

          <ul>
            <h2 className="h2b">GRAMMAR</h2>
            {tags?.grammar?.map((el) => (
              <Checkbox
                onClick={() => {
                  handleCheckClick("grammar", el.value);
                }}
                text={el.label}
                isActive={isSelected(el.value)}
              />
            ))}
          </ul>

          <ul>
            <h2 className="h2b">SUBSCRIPTION PLAN</h2>
            {pricingTags?.suscription?.map((el) => (
              <Checkbox
                onClick={() => {
                  handleSusClick("suscription", el.value);
                }}
                text={el.label}
                isActive={isSusSelected(el.value)}
              ></Checkbox>
            ))}
          </ul>
        </div>
        <div className="filterFooter">
          <Button onClick={clearAllFilters} className="clearFilter">
            CLEAR ALL FILTERS
          </Button>
        </div>
      </div>
}
      <div className="lessonCards">
        <div className="lessonPlanPageCardsTitle">
        <Title size="l" weigth="black" hasUnderline>{onlyFavorites ? "My Lesson Plans" : "Lesson Plans"}</Title>
        </div>
        <div className="lessonCardsContainer">
          {lessonData.map((el) => {
            if (onlyFavorites) {
              if (userFavorites.includes(el._id)) {
                return <Card data={el} isFavorite={userFavorites.includes(el._id)}></Card>;
              }
            } else {
              return <Card data={el} isFavorite={userFavorites.includes(el._id)}></Card>;
            }
          })}
          {!lessonData.length && <NoResults isFavorites={onlyFavorites}></NoResults>}
        </div>
      </div>
    </div>
  );
}

export default LessonPlans;
