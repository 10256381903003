import React from "react";
import Title from "../title/title";
import "./styles.css";
import cardImageCircle from "../../../img/svg/cardImageCircle.svg"

const CardWithImage = ({
  imagePosition = "top",
  title,
  children,
  background,
  image,
}) => {

  const secondary = background === "secondaryBg"


  return (
    <div className={"cardWithImage "}>
      <div className={"cardWithImageImage image" + imagePosition}>
      <img src={image} className="cardWithImageInnerImage"></img>
        <img
        style={{zIndex:"-1"}}
          className="cardWithImageImage"
          src={cardImageCircle}
        ></img>
      </div>
      <div className={"cardWithImageBody "+ (secondary ? "ligthGrayBg noBorderCard" : "")}>
        <div className="cardWithImageTitle">
            <Title size="m" weigth="black">{title}</Title>
        </div>
        <div className="cardWithImageText">
            {children}
        </div>
      </div>
    </div>
  );
};

export default CardWithImage;
