import axios from "axios";
import { ReactSession } from 'react-client-session';
import { apiUrl } from "../../constants/network";
import { sessionRefresh } from '../sessionRefresh/sessionRefresh';


function loginUser(loginData, callBack) {
  var data = JSON.stringify(loginData);

  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/user/login",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
        const responseWithUser = response.data.user.response;
        responseWithUser.user = response.data.user.user;

        responseWithUser.user = {...responseWithUser.user, group: response.data.user.group};
        responseWithUser.resfeshInterval = setInterval( sessionRefresh, 3200000 );

        ReactSession.set("session", responseWithUser);

        callBack(responseWithUser);
    })
    .catch(function (error) {
        callBack(error);
    });
}

export { loginUser };
