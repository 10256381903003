import React from 'react'
import Title from '../title/title'
import Button from '../button'
import {useNavigate} from 'react-router-dom';
import "./styles.css";

const CallToAction = ({title, description, url, buttonText}) => {

  const navigate = useNavigate();

  return (
    <div className='callToAction primaryBg standarSidePadding'>
        <div className="CTATitle">
            <Title size="l" hasUnderline weigth="black" color="white">{title}</Title>
        </div>
        <div className="CTADescription">
            {description}
        </div>
        <div className="CTAButton">
            <Button onClick={()=>{navigate(url)}} type={'highLighted'}>{buttonText}</Button>
        </div>
    </div>
  )
}

export default CallToAction