import React, { useState } from "react";
import { SpinnerDotted } from "spinners-react";
import Button from "../../common/button";
import Modal from "../../common/modal/modal";
import Title from "../../common/title/title";
import "./styles.css";
import RecoverPasswordForm from "../../forms/recoverPasswordForm/RecoverPasswordForm";
import { confirmRecoverPassword } from "../../../network/user/user";

const ForgotPasswordModal = ({ isOpen, setIsOpen, isLoading, error, email }) => {
  const customStyles = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  };

  const[message, setMessage] = useState(null);

 const submitData = (data) => {
     const dataToValidate = {...data, email:email}
    confirmRecoverPassword(dataToValidate, (response)=>{
      if(response?.response?.data?.error){
        setMessage(response.response.data.error);
        return;
      }
      if(response?.data?.response?.message){
        setMessage(response.data.response.message);
        setTimeout(()=>{
          setIsOpen(false);
        },5000);
        return;
      }
      setMessage("Unexpected error, please try again later.");
    });
 }

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      customStyles={customStyles}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="emailValidationModal">
        {isLoading ? (
          <div className="modalSpinner">
            <SpinnerDotted
              size={70}
              thickness={180}
              speed={100}
              color="#36ad47"
            />
          </div>
        ) : (
          <>
            {error ? (
              <div className="emailValidationModalErrorMessage">
                <Title weigth="black" size="l">
                  Error
                </Title>
                <div className="emailValidationModalErrorSubMessage">
                  {error}
                </div>
                <Button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  type={"black"}
                >
                  Close
                </Button>
              </div>
            ) : (
              <div className="emailValidationModalMessage">
                <Title weigth="black" size="l">
                  Please, check your email!
                </Title>
                <div className="emailValidationModalSubMessage">
                We just sent you a code to reset your password, please check your spam too. Type the code below and specify a new password for your account.
                </div>
                <RecoverPasswordForm setData={submitData} />
                {message && <Title size="s" weigth="black">{message}</Title>}
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
