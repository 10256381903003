import Modal from "../../../../../common/modal/modal";
import Title from "../../../../../common/title/title";
import Button from "../../../../../common/button";
import { updateCategory, deleteCategory } from "../../../../../../network/characteristics/characteristics";

const GetCategoryModal = ({ data, setData, isOpen, setIsOpen }) => {
  const customStyles = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
  };

  const handleFormChange = (target) => {
    setData((oldData) => ({
      ...oldData,
      [target.target.name]: target.target.value,
    }));
  };

  const submitForm = () => {

    updateCategory(data, (response) => {

    });
  };

  const handleDelete = () => {
    deleteCategory(data._id, (response) => {
    })
  }

  return (
    <Modal customStyles={customStyles} isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="addLessonPlanModal">
        <div className="addLessonPlanModalTitle">
          <Title weigth={"black"} size="l">
            {data?.name}
          </Title>
          <div className="addLessonPlanModalBtnGp">
            <Button dialogData="delete this category?" onClick={handleDelete} type="black">
              Delete
            </Button>
            <Button onClick={submitForm} type="black">
              Update
            </Button>
          </div>
        </div>
        <div className="addLessonPlanForms">
          <div className="addLessonPlanModalBody">
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Name</div>
              <input
                name="name"
                value={data?.name}
                onChange={handleFormChange}
                className="addLessonPlanModalFormGroupInput"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GetCategoryModal;
