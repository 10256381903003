import React, { useState } from "react";
import star from "../../../img/svg/star.svg";
import { enrollUser } from "../../../network/user/user";
import Spinner from "../../common/spinner/Spinner";
import { VscError } from 'react-icons/vsc'
import {useNavigate} from 'react-router-dom'
import "./styles.css";
import { sessionRefresh } from "../../../network/sessionRefresh/sessionRefresh";
import { refreshUser } from "../../../session/sessionUtis";

const CodeInput = ({promo=true}) => {
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const submit = () => {
    setIsLoading(true);
    enrollUser(code, (response) => {
        if(response.error){
            setMessage(response.error);
        }
        setIsLoading(false);
        sessionRefresh();
        refreshUser();
        navigate("/account");
    });
  };

  return (
    <div className="codeInput">
      {!isLoading ? (
        <>
          <div className="codeInputTitle">
            <img className="codeInputTitleLogo" src={star}></img>
            <div className="">
              Do you have a{" "}
              <span className="codeInputTitleHighlight">group token </span>
              {
                promo && <>or{" "}
                <span className="codeInputTitleHighlight">promo code </span></>
              }?
            </div>

            <img className="codeInputTitleLogo" src={star}></img>
          </div>
          <div className="codeInputSubtitle">write it here!</div>
          <div className="codeInputInput">
            <input
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
            <button onClick={submit}>APPLY</button>
          </div>
          {message &&
          <>
            <div className="codeInputTitle">
            <span className="codeInputTitleHighlight">
                <VscError/>{message}</span>
            </div>
          </>}
        </>
      ) : (
        <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <Spinner></Spinner>
        </div>
      )}
    </div>
  );
};

export default CodeInput;
