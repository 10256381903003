import React from 'react'
import OrderForm from '../../forms/orderForm/orderForm';
import PayPalButtons from '../../payPal/PayPalButtons';
import Title from '../../common/title/title'
import { BiCheckCircle } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import CodeInput from "../../common/codeInput/CodeInput";
import { validateSuscription } from "../../../network/payments/payments";
import {getUserSession, refreshUser} from "../../../session/sessionUtis";

import './styles.css';
import { sessionRefresh } from '../../../network/sessionRefresh/sessionRefresh';

const Payment = () => {
    window.scrollTo(0,0);

    const userId = getUserSession()?.user._id;
    const navigate = useNavigate();
    const location = useLocation();
    const planData = location.state?.planData;

    const isGroup = ["groups", "premium duo"].includes(planData.name.toLowerCase());

    if(!planData){
        navigate("/pricing");
    }

    const onApprove = (responseData) => {
        const toValidateObj = {
            user_Id: userId,
            pricing_Id: planData._id,
            suscription_Id:responseData.subscriptionID
        }
        validateSuscription(toValidateObj, (response => {
            sessionRefresh();
            refreshUser();
            navigate("/activation", {state:{planData}});
        }));
    }

    return (
        <div className="paymentPage standarSidePadding">
            <div className="paymentTitle">
                <Title size="l" weigth="black" hasUnderline>Complete your order</Title>
            </div>
            {isGroup &&
                <div className="tutorial">
                    <div className="tutorialItem"><BiCheckCircle className="tutorialLogo" /> To activate other members' accounts in the plan, share with them the tokens! </div>
                    <div className="tutorialItem"><BiCheckCircle className="tutorialLogo"/> After payment simply go to your profile and click on the option “tokens”.</div>
                    <div className="tutorialItem"><BiCheckCircle className="tutorialLogo"/> Every person in the group will have to enter their token in the subscription option in their profile.</div>
                </div>
            }
            <div className="paymentForms">
                <div className="paymentFormsSummary">
                    <OrderForm data={planData}></OrderForm>
                </div>
                <div className="paymentFormsForm">
                    <div className="paymentFormsFormButtons">
                        <div className="paymentFormsFormButtonsTitle">
                            <Title size="m" weigth="black">PAYMENT</Title>
                        </div>
                        <div className="paymentFormsFormButtonsPay">
                            <PayPalButtons onApprove={onApprove} planId={planData["paypal_plan_id"]} />
                        </div>
                    </div>
                    <div className="paymentFormsFormCode">
                        <CodeInput/>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Payment
