import {getCharacteristics} from '../../../../../../network/characteristics/characteristics';
import Button from '../../../../../common/button';
import {useEffect, useState} from 'react';
import CharacteristicsTable from './CharacteristicsTable';
import './styles.css';
import AddCategoryModal from './addCategoryModal';
import GetCategoryModal from './getCategoryModal';

const CharacteristicsAdmin = () => {

    const [CharacteristicsData, setCharacteristicsData] = useState([]);
    const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
    const [getCategoryModalOpen, setGetCategoryModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    useEffect(() => {
        getCharacteristics((response => {
            setCharacteristicsData(()=>response);

        }))
    }, [])

    const onRowClick = (categoryClicked) => {
        setSelectedRow(categoryClicked);
        setGetCategoryModalOpen(true);
    }

    return (
        <div className="CharacteristicsPage">
            <div className="CharacteristicsPageHeader">
                <div className="CharacteristicsPageSearch">
                    <input className="CharacteristicsPageSearchBox" type="text" name="" id=""/>
                    <Button>Search</Button>
                </div>
                <div className="CharacteristicsPageHeaderButtons">
                    <Button onClick={()=>{setAddCategoryModalOpen(true)}} type="black">Add Category</Button>
                </div>
            </div>
            {
                (addCategoryModalOpen || getCategoryModalOpen)? (<></>) : (<CharacteristicsTable onRowClick={onRowClick} data={CharacteristicsData} />)
            }
            <AddCategoryModal setIsOpen={setAddCategoryModalOpen} isOpen={addCategoryModalOpen}></AddCategoryModal>
            <GetCategoryModal setIsOpen={setGetCategoryModalOpen} isOpen={getCategoryModalOpen} data={selectedRow} setData={setSelectedRow}></GetCategoryModal>
        </div>
    )
}

export default CharacteristicsAdmin;
