import React, { useState, useEffect } from "react";
import { getPricing } from "../../../network/pricing/pricing";
import Button from "../../common/button";
import Title from "../../common/title/title";
import PricingCard from "./pricingCard/pricingCard";
import { BiCheckCircle } from "react-icons/bi";
import faqicon from "../../../img/svg/faqicon.svg";
import "./styles.css";

import pbasic from "../../../../src/img/svg/pbasic.svg";
import ppremium from "../../../../src/img/svg/ppremium.svg";
import ppremiumduo from "../../../../src/img/svg/ppremium2.svg";
import pcorporate from "../../../../src/img/svg/pcorporate.svg";

import Notification from "../../common/notification/Notification";
import NotificationModal from "../../modals/notificationModal/notificationModal";

import { getUserSession } from "../../../session/sessionUtis";
import { useNavigate } from "react-router-dom";

const Pricing = () => {
  const navigate = useNavigate();
  const [pricingCards, setPricingCards] = useState([null, null, null]);

  const userSessionActual = getUserSession();
  const isUserLogged = !!userSessionActual;

  const isPremiumUser = isUserLogged && userSessionActual?.user?.userType !== "regular";

  useEffect(() => {
    getPricing((response) => {
      setPricingCards(() => response);
    });
  }, []);

  return (
    <>
      <div className="pricingPage standarSidePadding ">
        <div className="pricingDesc">
          <Title weigth="black" size="l" hasUnderline>
            Why subscribe?
          </Title>
          <div className="pricingDescText">
            <div className="pricingDescTextItem">
              <div className="pricingDescTextItemBullet">
                <BiCheckCircle />
              </div>
              <div className="">
                Get access to all our ready-to-use lesson plans and start using
                interactive online presentations with authentic materials and
                engaging activities that will help improve your students'
                communicative skills.
              </div>
            </div>
            <div className="pricingDescTextItem">
            <div className="pricingDescTextItemBullet">
                <BiCheckCircle />
              </div>
              <div className="">
              Our lesson plans cover levels A1 to C1, and we offer a wide range
              of relevant and up-to-date topics to keep your classes fresh and
              interesting. You can even bookmark your favorite ones to use later
              on!
              </div>
            </div>
            <div className="pricingDescTextItem">
            <div className="pricingDescTextItemBullet">
                <BiCheckCircle />
              </div>
              <div className="">
              By subscribing to We Plan, you will save valuable time and have the
              tools you need to boost your teaching. And best of all, there are
              no time restrictions on your subscription, so you can cancel or
              come back at any time!
              </div>
            </div>
          </div>
        </div>
        <div className="pricingCards">
          {pricingCards.map((card) => {
            let icon = pbasic;

            if (card?.name.toLowerCase() === "premium") icon = pcorporate;
            if (card?.name.toLowerCase() === "premium duo") icon = ppremiumduo;
            if (card?.name.toLowerCase() === "groups") icon = ppremium;

            return (
              <PricingCard
                data={card}
                icon={icon}
                isPremiumUser={isPremiumUser}
                session={isUserLogged}
              ></PricingCard>
            );
          })}
        </div>
      </div>
      <div className="pricingFaq infoBg">
        <div className="faqiconbg">
          <img
            src={faqicon}
          />
        </div>
        <div className="pricingFaqTitle">
          <Title weigth="black" size="l" hasUnderline={true}>
            Got any doubts?
          </Title>
        </div>
        <div className="pricingFaqButton">
          <Button
            onClick={() => {
              navigate("/faq");
            }}
            type={"black"}
          >
            Check FAQ
          </Button>
        </div>
      </div>
    </>
  );
};

export default Pricing;
