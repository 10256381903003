import React from 'react'
import "./styles.css";

const ConfirmationInput = ({onChange}) => {
    return (
        <div className="confirmationInput">
            <input onChange={onChange} placeholder="- - - - - -" maxLength="6" className="confirmationInputField" type="text"/>
        </div>
    )
}
export default ConfirmationInput
