import axios from "axios";
import { apiUrl } from "../../constants/network";
import { getSessionToken, getUserSession, setNewToken } from "../../session/sessionUtis";

function sessionRefresh() {

    const userData = getUserSession();

    var data = JSON.stringify({refreshToken: userData.token.refreshToken});

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiUrl + "/user/renew/token",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getSessionToken()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
          const newToken = response.data.response.AuthenticationResult.AccessToken;
          setNewToken(newToken);
      })
      .catch(function (error) {
          throw error;
      });
  }

  export { sessionRefresh }