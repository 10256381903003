import { ReactSession } from "react-client-session";
import { getUserDb } from "../network/user/user";

const getSessionToken = () => {
    const session = ReactSession.get("session")
    if(session?.token?.accessToken){
        return session.token.accessToken
    } else {
        return null;
    }
};

const getUserSession = () => {
    return ReactSession.get("session");
}

const setNewToken = (token) => {
    let actualData = ReactSession.get("session");
    actualData.token.accessToken = token;
    actualData.exp = actualData.exp + 3600;
    ReactSession.set("session", actualData);
}

const removeUserSession = () => {
    ReactSession.set("session", null);
}

const addLessonToUser = (lessonId) => {
    const actualUser = getUserSession();
    actualUser.user.course.push(lessonId);
    ReactSession.set("session", actualUser);
}

const removeLessonToUser = (lessonId) => {
    const actualUser = getUserSession();
    actualUser.user.course = actualUser.user.course.filter(el => el !== lessonId);
    ReactSession.set("session", actualUser);
}

const refreshUser = () => {
    getUserDb( response => {
        let actualData = ReactSession.get("session");
        actualData.user = response;
        ReactSession.set("session", actualData);
    })
}

const setQuery = (query) =>{
    ReactSession.set("query", query);
}

const getQuery = () => {
   return ReactSession.get("query");
}


export { getSessionToken, setNewToken, getUserSession, addLessonToUser, removeLessonToUser, removeUserSession, refreshUser, setQuery, getQuery }