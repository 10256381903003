import "./styles.css";

const OrderForm = ({ data }) => {

  if(!data?.price){
    return;
  }

  return (
    <div className="orderBox">
      <div className="orderFormTitle">Your Order</div>

      <div className="orderDoubleFormInput">
        <div className="orderFormGroup">
        <div className="orderFormLabelB">Subscription</div>
          <div className="orderFormLabel">{data?.name}</div>
        </div>
        <div className="orderFormGroup">
            <div className="orderFormLabel"></div>
            <div className="orderFormLabel">{"$"+data?.price + ".00 / month"}</div>
          </div>
      </div>

      <div className="orderFormGroup">
        <div className="orderFormLabelB">Subtotal</div>
        <div className="orderFormLabel">{"$"+data?.price+".00"}</div>
      </div>

      <div className="orderFormGroup">
        <div className="orderFormLabelB">Recurring totals</div>
        <div className="orderFormLabel">{"$"+data?.price+".00"}</div>
      </div>

      <div className="orderFormGroup">
        <div className="orderFormLabelB">Total</div>
        <div className="orderFormLabel">
          {"$"+data.price + ".00 / month"}
        </div>
      </div>
    </div>
  );
};

export default OrderForm;
