import React from "react";
import "./styles.css";

const AdminNav = ({ activeIndex, items, setActiveIndex }) => {
  return (
    <nav className="adminNav infoBg">
      {items.map((item, index) => (
        <div
          onClick={() => {
            setActiveIndex(index);
          }}
          className={
            index === activeIndex ? "adminNavItemSelected" : "adminNavItem"
          }
        >
          {item.label}
        </div>
      ))}
    </nav>
  );
};

export default AdminNav;
