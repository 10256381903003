import React from 'react'
import './styles.css'

const TitledImage = ({image, title, subtitle}) => {
    return (
        <div className="titledImage">
            <img src={image} />
            <div className="titledImageLabel">
                <div className="imageLabelTitle">
                    {title}
                </div>
                <div className="imageLabelSubTItle">
                    {subtitle}
                </div>
            </div>
        </div>
    )
}

export default TitledImage
