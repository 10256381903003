import Title from "../title/title";
import "./styles.css";
import {
  BsFillBarChartFill,
  BsBookmark,
  BsFillBookmarkFill,
} from "react-icons/bs";
import { AiOutlineClockCircle, AiOutlineFileText } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Button from "../button";

export default function Card({ data, isFavorite, isCarousel }) {
  const navigate = useNavigate();

  return (
    <div className="card infoBg">
      <div className="cardHeader">
        <img
          className={data.level.split("/")[0].toLowerCase()}
          src={data.lessonImage}
          onClick={() => {
            navigate("/lesson/" + data._id);
          }}
        ></img>
        {isFavorite ? (
          <>
            <BsFillBookmarkFill className={"cardHeaderBookMarkFill"} />
            <BsBookmark className={"cardHeaderBookMark"} />
          </>
        ) : (
          <BsBookmark className={"cardHeaderBookMark"} />
        )}
      </div>
      <div className="cardContent">
        <div className={isCarousel && "cardBodyCarousel"}>
          <div
            onClick={() => {
              navigate("/lesson/" + data._id);
            }}
            className="cardBodyTitle"
          >
            <Title size={"m"} weigth="black">
              {data.title}
            </Title>
          </div>
          <div className="cardBodyText">{data.description}</div>
        </div>
        <div className="cardFooter">
          <div className="">
            <BsFillBarChartFill />
            {data.level} &nbsp;&nbsp;&nbsp;
            <AiOutlineClockCircle />
            {data.duration}min &nbsp;
          </div>
          <div className="">
            <AiOutlineFileText />
            {data?.category}
          </div>
          <div className="cardFooterLastDetails">
            <div className="cardFooterSus">{data.suscription}</div>
            <div className="a">
              <Button
                type={"black"}
                hover
                onClick={() => {
                  navigate("/lesson/" + data._id);
                }}
              >
                View more
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CardContainer({ children }) {
  <div className="cardContainer">{children}</div>;
}
