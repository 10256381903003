import {getUsers} from '../../../../../../network/user/user';
import Button from '../../../../../common/button';
import {useEffect, useState} from 'react';
import UserTable from './UserTable';
import './styles.css';

const UsersAdmin = () => {

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        getUsers((response => {
            setUserData(()=>response);
        }))
    }, [])


    return (
        <div className="userAdminPage">
            <div className="userAdminPageHeader">
                <div className="userAdminPageSearch">
                    <input className="userAdminPageSearchBox" type="text" name="" id=""/>
                    <Button>Search</Button>
                </div>
                <div className="userAdminPageHeaderButtons">

                </div>
            </div>
            <UserTable data={userData} />
        </div>
    )
}

export default UsersAdmin
