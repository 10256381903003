import Modal from "../../../../../common/modal/modal";
import Title from "../../../../../common/title/title";
import { getTags } from "../../../../../../network/summary/tagSummary";
import { useState, useEffect } from "react";
import Button from "../../../../../common/button";
import { MultiSelect } from "react-multi-select-component";
import { grammar } from "../../../../../../constants/weplan";
import { addCourse } from "../../../../../../network/course/course";

const AddLessonPlanModal = ({ isOpen, setIsOpen }) => {
  const customStyles = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
  };

  const [isReady, setIsReady] = useState(false);

  const[tags, setTags] = useState({level:[], grammar:[], characteristic:[]});
  const[levelTags, setLevelTags] = useState([]);
  const[grammarTags, setGrammarTags] = useState([]);
  const[categoryTags, setCategoryTags] = useState([]);


  const [lessonData, setLessonData] = useState({
    description: null,
    duration : '15',
    feedback: null,
    level: 'A1',
    suscription: "free",
    grammar: grammar[0],
    task: null,
    title: null,
    category: null,
    urlLesson: null,
    warmUp: null,
    input: null,
    vocabulary: null,
    lessonImage: null,
  });


  const handleFormChange = (target) => {
    setLessonData(oldData => ({...oldData, [target.target.name]:target.target.value}));
  }

  const handleTagChange = (name, value) => {

    if(name === "level") setLevelTags(value);
    if(name === "grammar") setGrammarTags(value);
    if(name === "category") setCategoryTags(value);

    const formatValue = value.map(el => el.value);
    setLessonData(oldData => ({...oldData, [name]:formatValue}));
  }

  const handleImageChange = (target) => {
    setLessonData(oldData => ({...oldData, [target.target.name]:target.target.value, fileInput:target.target}));
  }

  const submitForm = () => {
    addCourse(lessonData, ( response )=>{});
  }

  useEffect(() => {
    getTags((response)=>{
        setTags(()=>response);
    })
  }, []);

  useEffect(() => {
      const missing = Object.values(lessonData).filter(el=>( !el || el==="" || el==[] || el?.length===0 ))
      setIsReady( missing.length < 1 );
  }, [lessonData])


  return (
    <Modal customStyles={customStyles} isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="addLessonPlanModal">
        <div className="addLessonPlanModalTitle">
          <Title weigth={"black"} size="l">
            Add Lesson Plan
          </Title>
          <Button disabled={!isReady} onClick={ () => {submitForm(); setIsOpen(false)}} type="black" dialogData={"Create this lesson plan"}> Submit </Button>
        </div>
        <div className="addLessonPlanForms">
          <div className="addLessonPlanModalBody">
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Title</div>
              <input name="title" onChange={handleFormChange} className="addLessonPlanModalFormGroupInput" type="text" />
              <div className="modalInputCounter">{lessonData.title?.length}</div>
            </div>
            <div className="addLessonPlanModalDouble">

            <div className="addLessonPlanModalFormGroup f1">
              <div name="duration" className="addLessonPlanModalFormGroupLabel">
                Duration
              </div>
              <select name="duration" onChange={handleFormChange} className="addLessonPlanModalFormGroupInput">
                  <option value="15">15 min</option>
                  <option value="30">30 min</option>
                  <option value="45">45 min</option>
                  <option value="60">60 min</option>
                  <option value="75">75 min</option>
                  <option value="90">90 min</option>
                  <option value="105">105 min</option>
                  <option value="120">120 min</option>
              </select>
            </div>

            <div className="addLessonPlanModalFormGroup f1">
              <div className="addLessonPlanModalFormGroupLabel">
                Level
              </div>
             <MultiSelect
                value={levelTags}
                hasSelectAll={false}
                options={tags.level}
                onChange={(value)=>handleTagChange("level", value)}
                >
              </MultiSelect>
            </div>
            </div>

            <div className="addLessonPlanModalDouble">
            <div className="addLessonPlanModalFormGroup f1">
              <div className="addLessonPlanModalFormGroupLabel">
                Subscription
              </div>
              <select name="suscription" onChange={handleFormChange} className="addLessonPlanModalFormGroupInput">
                  <option value="free">FREE</option>
                  <option value="premium">PREMIUM</option>
              </select>
            </div>
            <div className="addLessonPlanModalFormGroup f1">
              <div className="addLessonPlanModalFormGroupLabel">
                Grammar
              </div>
              <MultiSelect
              value={grammarTags}
                onChange={(value)=>handleTagChange("grammar", value)}
                hasSelectAll={false}
                options={tags.grammar}>
                </MultiSelect>
            </div>

            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">URL</div>
              <input name="urlLesson" onChange={handleFormChange} className="addLessonPlanModalFormGroupInput" type="text" />
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Description</div>
              <textarea name="description" onChange={handleFormChange} rows="3" className="addLessonPlanModalFormGroupInput" />
              <div className="modalInputCounter">{lessonData.description?.length}</div>
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Image</div>
              <input name="lessonImage" onChange={handleImageChange} className="addLessonPlanModalFormGroupInput" type="file" />
            </div>
          </div>
          <div className="addLessonPlanModalBody">
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">
                Warm Up
              </div>
              <textarea name="warmUp" onChange={handleFormChange} rows="3" className="addLessonPlanModalFormGroupInput" />
              <div className="modalInputCounter">{lessonData.warmUp?.length}</div>
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Task</div>
              <textarea name="task" onChange={handleFormChange} rows="3" className="addLessonPlanModalFormGroupInput" />
              <div className="modalInputCounter">{lessonData.task?.length}</div>
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Grammar</div>
              <textarea name="feedback" onChange={handleFormChange} rows="3" className="addLessonPlanModalFormGroupInput" />
              <div className="modalInputCounter">{lessonData.feedback?.length}</div>
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Input</div>
              <textarea name="input" onChange={handleFormChange} rows="3" className="addLessonPlanModalFormGroupInput" />
              <div className="modalInputCounter">{lessonData.input?.length}</div>
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Vocabulary</div>
              <textarea name="vocabulary" onChange={handleFormChange} rows="3" className="addLessonPlanModalFormGroupInput" />
              <div className="modalInputCounter">{lessonData.vocabulary?.length}</div>
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Category:</div>
              <MultiSelect
              value={categoryTags}
              onChange={(value)=>handleTagChange("category", value)}
              hasSelectAll={false} options={tags.characteristic}></MultiSelect>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddLessonPlanModal;
