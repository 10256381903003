import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import "rsuite-table/dist/css/rsuite-table.css";
import React from 'react'

const UserTable = ({data, onRowClick}) => {

    return (
        <Table autoHeight data={data}>
          <Column resizable>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="_id" />
          </Column>
          <Column resizable>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>
          <Column resizable>
            <HeaderCell>Is Free</HeaderCell>
            <Cell dataKey="isFree">
            {(rowData) => {
                return rowData.isFree? "Yes" : "No";
            }}
            </Cell>
          </Column>
          <Column resizable>
            <HeaderCell>Price</HeaderCell>
            <Cell dataKey="price">
            {(rowData, rowIndex) => {
                return `$${rowData.price}`;
              }}
            </Cell>
          </Column>
          <Column width={400} resizable>
            <HeaderCell>Creation Date</HeaderCell>
            <Cell dataKey="createdAt" />
          </Column>
        </Table>
    )
}

export default UserTable;
