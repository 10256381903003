import React from "react";
import Title from "../../common/title/title";
import './styles.css';

const Terms = ({policy}) => {


    const dataMock = {
        termns: {
            title:"Terms and Conditions",
            items: [{
                className:"termsBody",
                value:`Welcome to WePlan ESL! By using our website, you agree to comply with
                these terms and conditions. If you do not agree to these terms, please
                do not use our website.`
            },
            {
                className:"termnsHigh",
                value:`Subscription Plans: `
            },
            {
                className:"termsBody",
                value:<>Users who subscribe to our premium individual, premium duo, or group
                plans agree to use the lesson plans included in their respective plan
                for personal use only. <br></br><br></br>Users may not share their usernames or passwords
                with anyone for premium individual plans. Premium duo plans are for two
                individuals only, with one credit card charged, and the account owner
                receiving a key to share with the other user. Group plans are available
                for up to five individuals, with one credit card charged, and the
                account owner receiving four keys to share with the other group members.
                Users agree to use our platform and the comments section in a
                professional and respectful manner. We reserve the right to remove any
                comments or content that violate our guidelines or standards of conduct.
                Users who access our website agree to respect our intellectual property
                rights and the rights of third-party content creators.  <br></br> <br></br>Users may not
                reproduce, distribute, modify, or publicly display any of our lesson
                plans, whether for personal or commercial use, without our prior written
                permission. Users agree to indemnify and hold us harmless from any
                claims, damages, or expenses that arise from their use of our platform
                or violation of these terms. Access to Content: Users can access both
                free and subscription content by registering with their email and
                creating a password on our website. Only one device can be accessed at
                the same time per person. Use of Content: Users must not share or
                reproduce our e-lesson plans outside our platform or commercialize them
                in any way. Users must also follow professional behavior when commenting
                on the lesson plans. <br></br><br></br> Any breach of these terms will result in legal
                consequences. Cancellation and Refunds: Members can cancel their
                subscription at any time and will not be charged for the following
                month. We do not offer refunds or returns. Third Party Content: We use
                authentic material such as YouTube videos or third-party websites, which
                means that we do not own that content. We will always try to make sure
                the third-party content is working, but if it is not, we will try to fix
                it as quickly as we are notified. Account Sharing: Premium Individual
                subscriptions are for individual use, and members should not share their
                usernames or passwords with anyone. <br></br><br></br> Premium Duo is to be used by two
                people only, and the group account is available for a maximum of 5
                people. Users are responsible for the security and confidentiality of
                their login information. If they suspect that someone else has gained
                access to their account, they must inform us immediately. We reserve the
                right to change these terms and conditions at any time without prior
                notice. It is the responsibility of the user to check for updates
                periodically. We are not responsible for any damages, losses, or
                liabilities that may arise from the use of our website. We hope you
                enjoy our platform and find it useful for your ESL learning needs. If
                you have any questions or concerns, please feel free to contact us at
                weplanesl@gmail.com. Thank you for choosing WePlan ESL!</>
            },
        ]
        },
        policy: {
            title: "Privacy Policy",
            items: [
                {
                    className:"termsBody",
                    value:`At We Plan ESL, we respect your privacy and are committed to protecting the personal information that you provide us. This Privacy Policy explains how we collect, use, and disclose your personal information when you use our website.`
                },
                {
                    className:"termnsHigh",
                    value:`Information we collect`
                },
                {
                    className:"termsBody",
                    value:`We collect the following types of personal information from you:
                    Name
                    Email address
                    We collect this information when you sign up to our website.
                    `
                },
                {
                    className:"termnsHigh",
                    value:`How we use your information`
                },
                {
                    className:"termsBody",
                    value:`We only use your personal information to send you newsletters or updates about our website. We do not share, sell, or otherwise disclose your personal information to any third parties.
                    `
                },
                {
                    className:"termnsHigh",
                    value:`How we protect your information`
                },
                {
                    className:"termsBody",
                    value:`We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. We use industry-standard encryption technology to protect your personal information during transmission and storage.`
                },
                {
                    className:"termnsHigh",
                    value:`Changes to this Privacy Policy`
                },
                {
                    className:"termsBody",
                    value:`We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.
                    If you have any questions about this Privacy Policy, please contact us at weplanesl@gmail.com.
                    `
                },
                {
                    className:"termnsHigh",
                    value:`Thank you for visiting our website.`
                },
            ]
        }
    }

    const data = policy ? dataMock.policy : dataMock.termns;

  return (
    <div className="termsPage standarSidePadding">
      <div className="termnsTitle">
        <Title hasUnderline size="l" weigth="black">
          {data.title}
        </Title>
      </div>
      {data.items.map(el => (
          <div className={el.className}> {el.value} </div>
      ))}
    </div>
  );
};

export default Terms;
