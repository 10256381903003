import React, { useState } from "react";
import { recoverPassword } from "../../../../network/user/user";
import Button from "../../../common/button";
import Title from "../../../common/title/title";
import ForgotPasswordModal from "../../../modals/forgot-password-modal/ForgotPasswordModal";
import "./styles.css";

const PasswordRecover = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState("");

    const submit = () => {
        recoverPassword(email, (response => {
            setIsModalOpen(true);
        }))
    }

  return (
    <div className="passwordRecovery standarSidePadding">
      <div className="passwordRecoveryForm">
        <div className="passwordRecoveryFormTitle">
          <Title weigth="black" hasUnderline size="l">Password Recovery</Title>
        </div>
        <div className="passwordRecoveryFormDesc">
          <b>Did you forget your password? Don’t worry!</b> <br/>
          Enter your email and we’ll send you a code to reset your password.

        </div>
        <div className="passwordRecoveryFormInput">
          <input onChange={event=>{setEmail(event.target.value)}} type="email"></input>
        </div>
        <div className="passwordRecoveryFormButton">
          <Button onClick={submit} type="black"> Submit </Button>
        </div>
      </div>

      <ForgotPasswordModal email={email} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </div>
  );
};

export default PasswordRecover;
