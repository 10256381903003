import axios from "axios";
import { apiUrl } from "../../constants/network";
import { getSessionToken } from "../../session/sessionUtis";

function getCharacteristics(callBack) {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: apiUrl + "/characteristic/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function deleteCategory(id, callBack){
  var config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: apiUrl + "/characteristic/"+id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function addCharacteristic(data, callBack){
  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/characteristic/",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
    data:data,
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

function updateCategory(data, callBack){
  var config = {
    method: "put",
    maxBodyLength: Infinity,
    url: apiUrl + "/characteristic/"+data._id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
    data:data,
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

export { getCharacteristics, addCharacteristic, updateCategory, deleteCategory }