export const grammar = [
    "Simple past",
    "Past continuous",
    "Past perfect",
    "Passive voice",
    "Simple present",
    "Present continuous",
    "Present perfect",
    "Indefinite pronouns",
    "Future",
    "Conditionals",
    "Future perfect and perfect continuous",
];