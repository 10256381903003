import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import "rsuite-table/dist/css/rsuite-table.css";
import React from 'react'

const UserTable = ({data}) => {

    const ImageCell = ({ rowData, dataKey, ...rest }) => (
    <Cell {...rest}>
        <img className="userAdminUserAvatar" src={rowData.userImage} width="50" />
    </Cell>
    );


    return (
        <Table autoHeight data={data}>
        <Column resizable>
            <HeaderCell>Avatar</HeaderCell>
            <ImageCell dataKey="userImage" />
          </Column>
          <Column resizable>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="_id" />
          </Column>
          <Column resizable>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="firstName" />
          </Column>
          <Column resizable>
            <HeaderCell>Last Name</HeaderCell>
            <Cell dataKey="lastName" />
          </Column>
          <Column resizable>
            <HeaderCell>Nick Name</HeaderCell>
            <Cell dataKey="username" />
          </Column>
          <Column resizable>
            <HeaderCell>Email</HeaderCell>
            <Cell dataKey="email">
              {(rowData, rowIndex) => {
                return <a href={`mailto:${rowData.email}`}>{rowData.email}</a>;
              }}
            </Cell>
          </Column>
          <Column resizable>
            <HeaderCell>Country</HeaderCell>
            <Cell dataKey="country" />
          </Column>
          <Column resizable>
            <HeaderCell>User Type</HeaderCell>
            <Cell dataKey="userType" />
          </Column>
        </Table>
    )
}

export default UserTable;
