import { getCourses } from "../../../../../../network/course/course";
import Button from "../../../../../common/button";
import { useEffect, useState } from "react";
import LessonPlansTable from "./LessonPlansTable";
import AddLessonPlanModal from "./addLessonPlanModal";
import "./styles.css";
import GetLessonPlanModal from "./getLessonPlanModal";
import { getCourse } from "../../../../../../network/course/course";

const LessonPlansAdmin = () => {
  const [LessonPlansData, setLessonPlansData] = useState([]);

  const [addLessonPlanModalIsOpen, setAddLessonPlanModalIsOpen] =
    useState(false);
  const [readLessonPlanModalIsOpen, setReadLessonPlanModalIsOpen] =
    useState(false);
  const [selectedLessonPlanData, setSelectedLessonPlanData] = useState(null);

  const getLessonPlan = (lessonPlanClicked) => {
    getCourse(lessonPlanClicked._id, (response) => {
      setSelectedLessonPlanData(response);
      setReadLessonPlanModalIsOpen(true);
    });
  };

  useEffect(() => {
    getCourses((response) => {
      setLessonPlansData(() => response);
    });
  }, []);

  return (
    <>
      <div className="LessonPlansAdminPage">
        <div className="LessonPlansAdminPageHeader">
          <div className="LessonPlansAdminPageSearch">
          </div>
          <div className="LessonPlansAdminPageHeaderButtons">
            <Button
              onClick={() => {
                setAddLessonPlanModalIsOpen(true);
              }}
              type="black"
            >
              Add LessonPlans
            </Button>
          </div>
        </div>
        {!addLessonPlanModalIsOpen && !readLessonPlanModalIsOpen && (
          <LessonPlansTable onRowClick={getLessonPlan} data={LessonPlansData} />
        )}
      </div>
      <AddLessonPlanModal
        isOpen={addLessonPlanModalIsOpen}
        setIsOpen={setAddLessonPlanModalIsOpen}
      ></AddLessonPlanModal>
      <GetLessonPlanModal
        setData={setSelectedLessonPlanData}
        data={selectedLessonPlanData}
        isOpen={readLessonPlanModalIsOpen}
        setIsOpen={setReadLessonPlanModalIsOpen}
      ></GetLessonPlanModal>
    </>
  );
};

export default LessonPlansAdmin;
