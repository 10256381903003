import Title from "../../common/title/title";
import React from "react";
import "./styles.css";

const SideMenu = ({ menuOptions, activeIndex = 1, setActiveIndex }) => {

  return (
    <div className="sideMenu">
      <div className="sideMenuTitle">
        <Title weigth="black" size="l" hasUnderline>
          Your Profile
        </Title>
      </div>
      {menuOptions.map((menuOption, index) => (
        <div
          onClick={() => {
            setActiveIndex(index);
          }}
          className={
            activeIndex === index ? "sideMenuItemSelected" : "sideMenuItem"
          }
        >
          {menuOption.icon}
          <span className="sideMenuItemLabel">&nbsp; {menuOption.label}</span>
        </div>
      ))}
    </div>
  );
};

export default SideMenu;
