import React, { useState } from 'react'
import AdminNav from './adminNav';
import DataTable from '../../../common/dataTable/DataTable';
import UsersAdmin from './adminPages/Users/UsersAdmin';
import PricingAdmin from './adminPages/Pricings/PricingAdmin';
import LessonPlansAdmin from './adminPages/lessonPlans/LessonPlansAdmin';
import "./styles.css";
import CharacteristicsAdmin from './adminPages/characteristics/CharacteristicsAdmin';


const Admin = () => {

    const[activeIndex, setActiveIndex] = useState(0);

    const adminItems = [
        {label:"Users", component:<UsersAdmin></UsersAdmin>},
        {label:"Lesson-plans",component:<LessonPlansAdmin/>},
        {label:"Categories",component:<CharacteristicsAdmin />},
        {label:"Pricing",component:<PricingAdmin></PricingAdmin>}
    ]

    return (
        <div className={"adminPage"}>
            <AdminNav items={adminItems} activeIndex={activeIndex} setActiveIndex={setActiveIndex}></AdminNav>
            <div className="adminPageBody">
                {adminItems[activeIndex].component}
            </div>
        </div>
    )
}

export default Admin
