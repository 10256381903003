import React, { useState } from 'react'
import './styles.css';
import Title from '../../common/title/title';
import Button from '../../common/button';
import contact from '../../../img/contactsvg.svg';
import {sendMessage} from '../../../network/contact/contact';

const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [notification, setSetnotification] = useState('');


    const submit = () => {

        if(name=='' || email=='' || message== '' || !name || !email || !message ){
            setSetnotification('Please fill all fields');
            return
        }

        const data = {name, email, message}

       sendMessage(data, (response)=>{
            setName('');
            setEmail('');
            setMessage('');
            setSetnotification('Message Sent!');
        });
    }

    return (
        <div className="contactPage">
            <div className="contactPageBody standarSidePadding">
                <img src={contact}></img>
                <div className="contactPageB">
                    <div className="contactPageTitle">
                    <Title weigth="black" size="m" hasUnderline>Let's get in touch</Title>
                    </div>
                <div className="contactForm">
                    <div className="contactFormInputs">
                        <input onChange={(e)=>{setName(e.target.value)}} value={name} type="text" placeholder="Your name"/>
                        <input onChange={(e)=>{setEmail(e.target.value)}} value={email} type="text" placeholder="Your email"/>
                        <textarea onChange={(e)=>{setMessage(e.target.value)}} value={message} type="text" placeholder="your message"/>
                    </div>

                    <div className="contactFormButtons">
                        <div className="contactFormButtonsNotification">{notification && notification}</div>
                        <Button onClick={submit} type="danger">Send</Button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
