import React, { useState } from "react";
import Button from "../../common/button";
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import "./styles.css";

const Form = ({
  setData,
  noBorder,
  formFields,
  setFormFields,
  title,
  buttonText,
  dialogData
}) => {
  const [formData, setFormData] = useState({});
  const [notification, setNotification] = useState([]);

  const fieldChange = (event, valueName) => {
    setFormFields((oldData) =>
      oldData.map((element) => {
        if (element.valueName == valueName) {
          element.actualValue = event.target.value;
        }
        return element;
      })
    );

    setFormData((oldData) => ({
      ...oldData,
      [valueName]: event.target.value,
    }));
  };

  const submit = () => {

    //TODO Cambiar la forma en la que se validan los sibblings
    const validatedFields = formFields.map( (el, index) => {

      el.errorMessage = null;

      if(el.type === "email" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(el.actualValue)){
        el.errorMessage = "Please provide a valid email";
      }
      if(el.type === "password" && !isStrongPassword(el.actualValue+"")){
        el.errorMessage = "Please enter a more secure password (min. length: 8, one lower case, one upper case, one number, one symbol)";
      }
      if(el.isRequire && (!el.actualValue || el.actualValue === '')){
        el.errorMessage = "Please fill "+el.label+" field.";
      }
      if(el.sameAsNext && el.actualValue != formFields[index+1].actualValue){
        el.errorMessage = el.label+" doesn't match!";
      }
      return el;
    });

    setFormFields(validatedFields);

    const formErrors = validatedFields.filter(el => {
      if(el?.errorMessage){
        return el;
      }
    });

    setNotification(formErrors);

    if(formErrors.length){
      return;
    } else {
      setData(formData);
    }
  };

  return (
    <div className={noBorder ? "form noBorderForm" : "form"}>
      <div className="formTitle">{title}</div>
      {formFields.map((e) => {
        return (
          <div className={e?.sibbling ? "doubleFormInput" : ""}>
            <div className="formGroup">
              <div className="formLabel">
                {e.label} {e.isRequire && "*"}
              </div>
              <div className="formInputField">
                {e?.isCombo ? (
                  <>
                    <select
                      onChange={(event) => fieldChange(event, e.valueName)}
                      required={e.isRequire}
                      name={e.valueName}
                      className={e?.errorMessage && "inputError"}
                    >
                      {e?.options.map((optionElement) => (
                        <option value={optionElement.value}>
                          {optionElement.title}
                        </option>
                      ))}
                    </select>
                  </>
                ) : (
                  <>
                    <input
                      placeholder={e?.placeholder}
                      onChange={(event) => fieldChange(event, e.valueName)}
                      required={e.isRequire}
                      name={e.valueName}
                      type={e.type}
                      className={e?.errorMessage && "inputError"}
                    />
                  </>
                )}
              </div>
            </div>
            {e?.sibbling && (
              <>
                <div className="formGroup">
                  <div className="formLabel">
                    {e.sibbling.label} {e.sibbling.isRequire && "*"}
                  </div>
                  <div className="formInputField">
                    {e?.sibbling.isCombo ? (
                      <>
                        <select
                          onChange={(event) =>
                            setFormData((oldData) => ({
                              ...oldData,
                              [e.sibbling.valueName]: event.target.value,
                            }))
                          }
                          required={e.sibbling.isRequire}
                          name={e.sibbling.valueName}
                          className={e?.errorMessage && "inputError"}
                        >
                          {e?.sibbling.options.map((optionElement) => (
                            <option value={optionElement.value}>
                              {optionElement.title}
                            </option>
                          ))}
                        </select>
                      </>
                    ) : (
                      <>
                        <input
                          onChange={(event) =>
                            setFormData((oldData) => ({
                              ...oldData,
                              [e.sibbling.valueName]: event.target.value,
                            }))
                          }
                          required={e.sibbling.isRequire}
                          name={e.sibbling.valueName}
                          type={e.sibbling.type}
                          placeholder={e?.sibbling?.placeholder}
                          className={e?.errorMessage && "inputError"}
                        />
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
      {
        !!notification.length && (
          <div className="formNotification">
            {notification.map((el)=>(
              <p>{el.errorMessage}</p>
            ))}
          </div>
        )
      }
      <div className="formGroup formFlexRigth">
        <Button dialogData={dialogData} onClick={submit} type="black">
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default Form;
