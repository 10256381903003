import Modal from '../modal/modal';
import React from 'react';
import {TfiAlert } from "react-icons/tfi";
import "./styles.css";
import Button from '../button';

const Dialog = ({isOpen, setIsOpen, label, onConfirm, onCancel}) => {

    const customStyles = {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      };

    return (
        <Modal customStyles={customStyles} isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="dialogBox">
                <div className="dialogBoxIcon"> <TfiAlert></TfiAlert></div>
                <div className="dialogBoxLabel">Do you want to <b>{label}</b></div>
                <div className="dialogBoxButtons">
                    <Button onClick={()=>{onConfirm(); setIsOpen(false);}} type="black">Yes</Button>
                    <Button onClick={onCancel} type="outlined">Dismiss</Button>
                </div>
            </div>
        </Modal>
    )
}

export default Dialog;