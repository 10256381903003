import React from 'react'
import Iframe from 'react-iframe'
import './styles.css'

const Genially = ({url, title}) => {

    const urlRegex = /https:\/\/view.genial.ly\/[A-Z0-9a-z]+/

    const formatedRegex = urlRegex.exec(url);

    if(!formatedRegex){
        return <div>ERROR DE URL</div>
    }

    return (
        <div className="lessonContentFrame">
            <Iframe
                url={formatedRegex[0]}
                width={"100%"}
                height={"500px"}
                styles={{border:'none', borderRadius:"25px 25px 0 0"}}
            />
            <div className="contentFooter">
                {title}
            </div>
        </div>
    )
}

export default Genially
