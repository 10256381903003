import React, { useState } from "react";
import Form from "../../common/form/form";

const RegisterForm = ({ setData }) => {

  const registerFormData = [
    {
      label: "First name",
      isRequire: true,
      type: "text",
      actualValue: null,
      valueName: "firstName",
    },
    {
      label: "Last name",
      isRequire: true,
      type: "text",
      actualValue: null,
      valueName: "lastName",
    },
    {
      label: "Username",
      isRequire: true,
      type: "text",
      actualValue: null,
      valueName: "username",
    },
    {
      label: "Email address",
      isRequire: true,
      type: "email",
      actualValue: null,
      valueName: "email",
      sameAsNext: true,
    },
    {
      label: "Confirm email address",
      isRequire: true,
      type: "email",
      actualValue: null,
      valueName: "emailConfirmation",
    },
    {
      label: "Password",
      isRequire: true,
      type: "password",
      actualValue: null,
      valueName: "password",
      sameAsNext: true,
    },
    {
      label: "Confirm password",
      isRequire: true,
      type: "password",
      actualValue: null,
      valueName: "passwordConfirmation",
    },
  ];

  const [formFields, setFormFields] = useState(registerFormData);

  return (
    <Form title={"Register"} buttonText={"Register"} formFields={formFields} setFormFields={setFormFields} setData={setData}></Form>
  );
};

export default RegisterForm;
