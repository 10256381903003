import React from "react";
import Title from "../../common/title/title";
import TitledImage from "../../common/titledImage/TitledImage";

import ana from "../../../img/ana.jpeg";
import vale from "../../../img/vale.jpeg";
import seba from "../../../img/seba.jpeg";
import negro from "../../../img/negro.jpeg";
import kelly from "../../../img/kelly.jpg";

import "./styles.css";

const AboutUs = () => {
  const people = [
    {
      name: "Kelly Salazar",
      title: "Co-founder, academic director",
      pic: kelly,
    },
    {
      name: "Ana María Alzate",
      title: "Co-founder, design director",
      pic: ana,
    },
    {
      name: "Sebastián Anderson",
      title: "Front-end developer",
      pic: seba,
    },
    {
      name: "Valentina Urrea",
      title: "UI designer",
      pic: vale,
    },
    {
      name: "Andres C. Hoyos",
      title: "Back-end developer",
      pic: negro,
    },
  ];

  return (
    <div className={"aboutUsPage standarSidePadding"}>
      <div className="aboutUsDescription">
        <div className="aboutUsDescriptionTitle">
          <Title hasUnderline weigth="black" size="m">
            About us
          </Title>
        </div>
        <div className="aboutUsDescriptionText">
          We are a multidisciplinary team committed to the creation of high
          quality, interactive, educational content for ESL teaching.
          <br></br> <br></br>

          All our materials are created by experienced ESL teachers and are later on meticulously designed by our educational design team to ensure they are visually captivating and coherent. <br></br> <br></br>

          We also count on our IT team that makes it possible for all teachers to access the interactive lessons and to navigate the website in a simple and practical way!

        </div>
      </div>
      <div className="aboutUsImages">
        {people.map((el) => (
          <TitledImage
            title={el.name}
            image={el.pic}
            subtitle={el.title}
          ></TitledImage>
        ))}
      </div>
    </div>
  );
};

export default AboutUs;
