import SideMenu from "../../common/sideMenu/sideMenu";
import AccountDetails from "./accountDetails/accountDetails";
import Admin from "./admin/admin";
import AccountCodes from './accountCodes/AccountCodes';
import {getUserSession} from "../../../session/sessionUtis";
import {
  AiOutlineUser,
  AiOutlineProfile,
} from "react-icons/ai";
import { GrUserAdmin } from "react-icons/gr";
import "./styles.css";
import { useState, useEffect } from "react";
import AccountSubscription from "./accountSubscription/AccountSubscription";

const Account = () => {
  window.scrollTo(0,0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [userSession, setUserSession] = useState();
  const [menuOptions, setMenuOptions] = useState([
    { component:<AccountDetails></AccountDetails>, icon: <AiOutlineUser />, label: "Account details" },
  ]);
  const adminLabel = { component: <Admin/>, icon: <GrUserAdmin />, label:"Admin" };
  const groupLabel = { component: <AccountCodes/>, icon: <GrUserAdmin />, label:"Group tokens" };
  const susLabel =  { component:<AccountSubscription></AccountSubscription>, icon: <AiOutlineProfile />, label: "Subscription" };

  useEffect(() => {
      const tempSession = getUserSession();

      if(!tempSession){
          //TODO, no hay sesssion, se redirije a landing page
      }
      setUserSession(()=>tempSession);

      if (tempSession.user.userType === "admin"){
        setMenuOptions((oldOptions)=>([adminLabel,...oldOptions]));
      }

      if(tempSession.user.group){
        setMenuOptions((oldOptions)=>([...oldOptions, groupLabel]));
      }

      if(!tempSession?.user?.manual){
        setMenuOptions((oldOptions)=>([...oldOptions, susLabel]));
      }

  }, [])

  return (
    <div className="accountPageContainer standarSidePadding">
      <SideMenu
        menuOptions={menuOptions}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        admin
      ></SideMenu>
      {menuOptions[activeIndex].component}
    </div>
  );
};

export default Account;
