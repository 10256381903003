import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ReactSession } from "react-client-session";
import logo from "../../img/svg/logo.svg"
import "./styles.css";
const HeaderBurger = ({ hasSession, onLoginClick, onSign }) => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();


  const onLogOutClick = () => {
    ReactSession.set("session", null);
    ReactSession.set("user", null);
    navigate("/");
  };

  return (
    <>
      <div onClick={() => setIsActive(!isActive)} className="headerBurger">
        <GiHamburgerMenu></GiHamburgerMenu>
      </div>
      <div
        className={
          "burgerMenu dangerBg " + (isActive ? "burgerMenuActive" : "")
        }
      >
        <div className="burgerMenuContent">
          <div className="burgerMenuHeader">
            <div onClick={()=>{setIsActive(false); navigate("/")}} className="burgerMenuLogo">
              <img src={logo}></img>
            </div>
            <div
              onClick={() => setIsActive(false)}
              className="burgerMenuCloseButton"
            >
              <AiOutlineCloseCircle />
            </div>
          </div>
          {
            hasSession ? (
              <div onClick={()=>{setIsActive(false); navigate("/account")}} className="burgerMenuItem">ACCOUNT</div>
            ) : (<>
              <div onClick={()=>{setIsActive(false); onLoginClick()}} className="burgerMenuItem">LOG IN</div>
              <div onClick={()=>{setIsActive(false); onSign()}} className="burgerMenuItem">SIGN UP</div>
              </>)
          }
          <div onClick={()=>{setIsActive(false); navigate("/lesson-plans")}} className="burgerMenuItem">LESSON PLANS</div>
          <div onClick={()=>{setIsActive(false); navigate("/pricing")}} className="burgerMenuItem">PRICING</div>
          <div onClick={()=>{setIsActive(false); navigate("/about-us")}} className="burgerMenuItem">ABOUT US</div>
          <div onClick={()=>{setIsActive(false); navigate("/contact")}} className="burgerMenuItem">CONTACT</div>
          {
            hasSession &&  <div onClick={()=>{setIsActive(false); onLogOutClick()}} className="burgerMenuItem">LOG OUT</div>
          }
        </div>
      </div>
    </>
  );
};

export default HeaderBurger;
