import Modal from "../../../../../common/modal/modal";
import Title from "../../../../../common/title/title";

import { useState, useEffect } from "react";
import Button from "../../../../../common/button";
import { getTags } from "../../../../../../network/summary/tagSummary";
import { MultiSelect } from "react-multi-select-component";
import {
  updateCourse,
  deleteCourse,
} from "../../../../../../network/course/course";

const GetLessonPlanModal = ({ data, setData, isOpen, setIsOpen }) => {
  const customStyles = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
  };

  const [isReady, setIsReady] = useState(true);
  const [initialData, setInitialData] = useState({});

  const[tags, setTags] = useState({level:[], grammar:[], characteristic:[]});
  const[levelTags, setLevelTags] = useState([]);
  const[grammarTags, setGrammarTags] = useState([]);
  const[categoryTags, setCategoryTags] = useState([]);

  const handleFormChange = (target) => {
    setData((oldData) => ({
      ...oldData,
      [target.target.name]: target.target.value,
    }));
  };

  const handleTagChange = (name, value) => {

    if(name === "level") setLevelTags(value);
    if(name === "grammar") setGrammarTags(value);
    if(name === "category") setCategoryTags(value);

    const formatValue = value.map(el => el.value);
    setData(oldData => ({...oldData, [name]:formatValue}));
  }

  const handleImageChange = (pictureFile) => {
    setData((oldData) => ({ ...oldData, lessonImage: pictureFile }));
  };

  const onDelete = () => {
    deleteCourse(data._id, (response) => {
      setIsOpen(false);
    });
  };

  const submitForm = () => {
    updateCourse(data, (response) => {
    });
  };

  useEffect(() => {
    setInitialData(data);
    getTags((response)=>{
      setTags(()=>response);
  });
  }, []);

  useEffect(() => {

    setLevelTags(()=>{
      const levelArray = data?.level.map(el => el._id || el)
      return tags.level?.filter(el=> levelArray.includes(el.value)
    )});

    setCategoryTags(()=>{
      const categoryArray = data?.category.map(el => el._id || el);
      return tags.characteristic?.filter(el => categoryArray.includes(el.value)
    )});

    setGrammarTags(()=>{
      const grammarArray = data?.grammar.map(el => el._id || el);
      return tags.grammar?.filter(el => grammarArray.includes(el.value)
    )});

  }, [data]);

  return (
    <Modal customStyles={customStyles} isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="addLessonPlanModal">
        <div className="addLessonPlanModalTitle">
          <Title weigth={"black"} size="l">
            {data?.title}
          </Title>
          <div className="addLessonPlanModalBtnGp">
            <Button onClick={onDelete} type="black" dialogData="Delete this lesson plan?">
              Delete
            </Button>
            <Button disabled={!isReady} onClick={submitForm} type="black">
              Update
            </Button>
          </div>
        </div>
        <div className="addLessonPlanForms">
          <div className="addLessonPlanModalBody">
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Title</div>
              <input
                name="title"
                onChange={handleFormChange}
                className="addLessonPlanModalFormGroupInput"
                type="text"
                value={data?.title}
              />
            </div>
            <div className="addLessonPlanModalDouble">
              <div className="addLessonPlanModalFormGroup f1">
                <div
                  name="duration"
                  className="addLessonPlanModalFormGroupLabel"
                >
                  Duration
                </div>
                <select
                  name="duration"
                  onChange={handleFormChange}
                  className="addLessonPlanModalFormGroupInput"
                >
                  <option selected={"15" === data?.duration} value="15">15 min</option>
                  <option selected={"30" === data?.duration} value="30">30 min</option>
                  <option selected={"45" === data?.duration} value="45">45 min</option>
                  <option selected={"60" === data?.duration} value="60">60 min</option>
                  <option selected={"75" === data?.duration} value="75">75 min</option>
                  <option selected={"90" === data?.duration} value="90">90 min</option>
                  <option selected={"105" === data?.duration} value="105">105 min</option>
                  <option selected={"120" === data?.duration} value="120">120 min</option>
                </select>
              </div>

              <div className="addLessonPlanModalFormGroup f1">
                <div className="addLessonPlanModalFormGroupLabel">Level</div>
                <MultiSelect
                  value={levelTags}
                  hasSelectAll={false}
                  options={tags.level}
                  onChange={(value) => handleTagChange("level", value)}
                ></MultiSelect>
              </div>
            </div>

            <div className="addLessonPlanModalDouble">
              <div className="addLessonPlanModalFormGroup f1">
                <div className="addLessonPlanModalFormGroupLabel">
                  Subscription
                </div>
                <select
                  name="suscription"
                  onChange={handleFormChange}
                  className="addLessonPlanModalFormGroupInput"
                >
                  <option selected={"free" === data?.suscription} value="free">
                    FREE
                  </option>
                  <option
                    selected={"premium" === data?.suscription}
                    value="premium"
                  >
                    PREMIUM
                  </option>
                </select>
              </div>
              <div className="addLessonPlanModalFormGroup f1">
                <div className="addLessonPlanModalFormGroupLabel">Grammar</div>
                <MultiSelect
                  value={grammarTags}
                  onChange={(value) => handleTagChange("grammar", value)}
                  hasSelectAll={false}
                  options={tags.grammar}
                ></MultiSelect>
              </div>
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">URL</div>
              <input
                value={data?.urlLesson}
                name="urlLesson"
                onChange={handleFormChange}
                className="addLessonPlanModalFormGroupInput"
                type="text"
              />
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">
                Description
              </div>
              <textarea
                name="description"
                value={data?.description}
                onChange={handleFormChange}
                rows="3"
                className="addLessonPlanModalFormGroupInput"
              />
            </div>
          </div>
          <div className="addLessonPlanModalBody">
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Warm Up</div>
              <textarea
                value={data?.warmUp}
                name="warmUp"
                onChange={handleFormChange}
                rows="3"
                className="addLessonPlanModalFormGroupInput"
              />
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Task</div>
              <textarea
                value={data?.task}
                name="task"
                onChange={handleFormChange}
                rows="3"
                className="addLessonPlanModalFormGroupInput"
              />
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Feedback</div>
              <textarea
                value={data?.feedback}
                name="feedback"
                onChange={handleFormChange}
                rows="3"
                className="addLessonPlanModalFormGroupInput"
              />
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Input</div>
              <textarea
                value={data?.input}
                name="input"
                onChange={handleFormChange}
                rows="3"
                className="addLessonPlanModalFormGroupInput"
              />
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Vocabulary</div>
              <textarea
                value={data?.vocabulary}
                name="vocabulary"
                onChange={handleFormChange}
                rows="3"
                className="addLessonPlanModalFormGroupInput"
              />
            </div>
            <div className="addLessonPlanModalFormGroup">
              <div className="addLessonPlanModalFormGroupLabel">Category:</div>
              <MultiSelect
                value={categoryTags}
                onChange={(value) => handleTagChange("category", value)}
                hasSelectAll={false}
                options={tags.characteristic}
              ></MultiSelect>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GetLessonPlanModal;
