import { FaUser } from "react-icons/fa";
import { SpinnerDotted } from "spinners-react";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { BiCheckCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import Button from "../../../common/button";
import "./styles.css";

const PricingCard = ({ data, icon, session, isPremiumUser }) => {
  if (!data) {
    return (
      <div className="pricingCard">
        <div className="spinnerInCard">
          <SpinnerDotted
            size={70}
            thickness={180}
            speed={100}
            color="#36ad47"
          />
        </div>
      </div>
    );
  }

  const quantity = data.quantity_members;

  const getPeopleAmountLabel = () => {
    if (quantity == 1) {
      return (
        <>
          <FaUser></FaUser> Individual
        </>
      );
    }

    if (quantity == 2) {
      return (
        <>
          <FaUser></FaUser>
          <FaUser></FaUser> 2 people
        </>
      );
    }

    return (
      <>
        <FaUser />
        <FaUser />
        <FaUser /> {quantity} people
      </>
    );
  };

  return (
    <div className="pricingCard">
      <div className="pricingCardImage">
        <img src={icon} />
      </div>
      <div className="pricingCardTitle">{data.name}</div>
      <div className="pricingCardType">{getPeopleAmountLabel()}</div>
      <div className="pricingCardPrice">
        <div className="pricingCardPriceTitle">
          {data.isFree ? (
            <div className={"secondaryText"}>Free</div>
          ) : (
            <div className={"secondaryText"}>
              <RiMoneyDollarCircleLine></RiMoneyDollarCircleLine>
              {data.price + ".00"}
              <span className="priceSpan">/month</span>
            </div>
          )}
        </div>
      </div>
      <div className="pricingCardDescriptionBox">
        {data.descriptions.map((description) => (
          <div className="pricingCardDescription">
            <div className="pricingCardDescriptionBullet">
              <BiCheckCircle />{" "}
            </div>
            <div className="pricingCardDescriptionText">{data.isFree ? description.replace("lessons", "lesson") : description.replace("Get access ALL lesson plans in the platform", "get access to ALL lesson plans on the platform")}</div>
          </div>
        ))}
      </div>

      <div className="pricingSelectButton">

          {session && data.isFree ? (<>
          <Button disabled={true} hover type="black">
            Selected
          </Button>
          </>) :
          <Link
            to={session ? "/payment" : "/register"}
            state={{ planData: data }}
            className="link"
          >
            <Button disabled={isPremiumUser} hover type="black">
              Select
            </Button>
          </Link>
        }
      </div>
    </div>
  );
};

export default PricingCard;
