import React from 'react'
import Button from '../../common/button'

const LoginForm = () => {

    const loginFormInfo = [
        {
            label:"Email address",
            isRequire:true,
            type:"email",
            valueName:"loginEmail",
        },
        {
            label:"Password",
            isRequire:true,
            type:"password",
            valueName:"loginPassword",
        },
    ]

    return (
        <div className="form">
            <div className="formTitle">LOG IN</div>
            {
                loginFormInfo.map( e => (
                    <div className="formGroup">
                        <div className="formLabel">
                            {e.label} {e.isRequire && "*"}
                        </div>
                        <div className="formInputField">
                            <input name={e.valueName} type={e.type} />
                        </div>
                    </div>
                ))
            }
            <div className="formGroup formFlex">
                <Button>Forgot password?</Button>
                <Button type="black">Log in</Button>
            </div>
        </div>
    )
}

export default LoginForm
