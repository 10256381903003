import bannerImage from "../../../img/bannerImg.png";
import "./styles.css";

function Banner({ breakpoint }) {
  return (
    <div className=" standarSidePadding heroBanner ligthGrayBg">
      <div className="bannerBody">
        <div className="bannerBodyTitle secondaryText">
          Hi, teacher! <span className="primaryText">Innovation is waiting for you! </span>
        </div>
        <div className="bannerBodyItemList">
          <div className="bannerBodyItem bbi1">
            <div className="bodyItemLayout">
            <div className="bannerBodyTittle">
            At We plan
            </div>
            <div className="bannerBodyItemText">
              <div className="bannerBodyContent">
                We aim to make ESL teaching easier and enhance your
                classes with our interactive, cutting-edge lesson plans. Our
                ready-to-use plans are carefully curated to ensure high quality
                and contextualized content.
              </div>
            </div>
            </div>
          </div>
          <div className="bannerBodyItem bbi2">
            <div className="bodyItemLayout">
            <div className="bannerBodyTittle">
            We believe in
            </div>
            <div className="bannerBodyItemText">
              <div className="bannerBodyContent">
                In student-centered learning and the use of authentic
                materials to encourage critical thinking. Whether you're looking
                for free lesson plans or premium content, we have you covered
                with levels A1 to C1, classes ranging from 60 to 90 minutes, and
                a wide range of appealing topics.
              </div>
            </div>
            </div>
          </div>
          <div className="bannerBodyItem bbi3">
            <div className="bodyItemLayout">
            <div className="bannerBodyTittle">
            By subscribing
            </div>
            <div className="bannerBodyItemText">
              <div className="bannerBodyContent">
              You will save endless unpaid hours of planning.
                Experience We Plan: everything a book can't be!
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      {breakpoint !== "mobile" && (
        <div className="bannerImage">
          <img className="bannerImage" src={bannerImage} alt="Banner Image" />
        </div>
      )}
    </div>
  );
}

export default Banner;
