import { createContext, useState } from "react";

export const LanguageContext = createContext();

const english={
    lastestLessonPlans:"Latest lesson plans",
    lessonPlans:"Lesson plans",
    pricing: "Pricing",
    aboutUs: "About us",
    contact: "Contact",
    browseByTopics: "Browse by Topics",
}

const spanish={
    lessonPlans:"Planes de aprendizaje",
    pricing: "Precios",
    aboutUs: "Nosotros",
    contact: "Contáctanos",
}

export const LanguageProvider = ({children})=>{
    const [language, setLanguage] = useState(english);

    return(
        <LanguageContext.Provider value={{language, setLanguage}}>
            {children}
        </LanguageContext.Provider>
    )
}