import Title from "../../common/title/title";
import Button from "../../common/button";
import { ImStatsBars2 } from "react-icons/im";
import {
  BsClock,
  BsFileEarmarkText,
  BsFillBarChartFill,
  BsBookmark,
  BsFillBookmarkFill,
} from "react-icons/bs";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactGa from "react-ga";
import "./styles.css";
import CardWithImage from "../../common/cardWithImage/cardWithImage";
import { getCourse } from "../../../network/course/course";
import Spinner from "../../common/spinner/Spinner";
import { addLesson, removeLesson } from "../../../network/user/user";
import {
  addLessonToUser,
  getUserSession,
  removeLessonToUser,
} from "../../../session/sessionUtis";
import Start from "../../../img/svg/star.svg";

import warmup from "../../../img/svg/warmup.svg";
import input from "../../../img/svg/input.svg";
import task from "../../../img/svg/task.svg";
import feedback from "../../../img/svg/feedback.svg";
import vocabulary from "../../../img/svg/vocabulary.svg";
import Genially from "../../geniallyFrame/Genially";

import ReactPixel from 'react-facebook-pixel';

const Lesson = () => {
  const [lessonData, setLessonData] = useState(null);
  const [buttonMessage, setButtonMessage] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [showGenially, setShowGenially] = useState(false);

  const { id } = useParams();

  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('3596601190593489', {}, options);
  ReactPixel.pageView();

  useEffect(() => {
    window.scrollTo(0, 0);

    ReactGa.pageview(window.location.pathname);

    getCourse(id, (response) => {
      setLessonData({ ...response });

      if (response.urlLesson === "Not available") {
        setButtonMessage("Get premium to open this lesson");
      }

      if (response.urlLesson === "Not available for your subscription") {
        setButtonMessage("Get Premium for open this lesson");
      }

      if (getUserSession()?.user.course.includes(response._id)) {
        setIsFavorite(true);
      }
    });
  }, []);

  const handleFavoriteClick = () => {
    if (isFavorite) {
      removeLesson(lessonData._id, (response) => {
        setIsFavorite(false);
        removeLessonToUser(lessonData._id);
      });
      return;
    }

    addLesson(lessonData._id, (response) => {
      setIsFavorite(true);
      addLessonToUser(lessonData._id);
    });
  };

  return (
    <div className="lessonPage standarSidePadding">
      <div className="lessonPageIntro">
        <div className="lessonPageImage">
          {lessonData ? (
            <img src={lessonData?.lessonImage}></img>
          ) : (
            <div className="lessonPageLoadingImage">
              {" "}
              <Spinner />{" "}
            </div>
          )}
        </div>
        <div className="lessonPageIntroDetails">
          <div className="lessonPageIntroDetailsTitle">
            <Title weigth={"black"} size={"l"} hasUnderline>
              {lessonData?.title}
            </Title>
          </div>
          <div className="lessonPageIntroDetailsDescription">
            {lessonData?.description}
          </div>
          <div className="lessonPageIntroDetailsLevel">
            <div className="lessonPageIntroDetailsLevelItem">
              <div>
                <ImStatsBars2 className={"secondaryText"} />
                {lessonData?.level?.map((el) => el.name).join("/")}
              </div>
              <div>
                <BsClock className={"secondaryText"} /> {lessonData?.duration}{" "}
                min
              </div>
              <div>
                <BsFileEarmarkText className={"secondaryText"} />{" "}
                {lessonData?.category?.map((el) => el.name).join("/")}
              </div>
            </div>
            <div className="lessonPageIntroDetailsLevelPlan">
              <div className="planPill marginAutoR">
                {lessonData?.suscription}
              </div>
              <div
                onClick={handleFavoriteClick}
                className="lessonPageIntroDetailsFavorite"
              >
                {isFavorite ? (
                  <>
                  <div className="bookmarkGpLesson">
                  <BsFillBookmarkFill className={"lessonPageSaved"} />
                    <BsBookmark className={"lessonPageSavedLine"} />
                  </div>
                    Lesson Saved
                  </>
                ) : (
                  <>
                    <BsBookmark className={"lessonPageSaveL"} />
                    Save this lesson
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="lessonPageIntroDetailsCta">
            <Button
              onClick={() => {
                ReactPixel.track("openLesson", {id});
                setShowGenially(!showGenially);
              }}
              disabled={!!buttonMessage}
              type="black"
            >
              {buttonMessage ? buttonMessage : "Open lesson"}
            </Button>
          </div>
        </div>
      </div>

      {showGenially && (
        <div className="lessonContainer">
          <Genially
            title={lessonData.title}
            url={lessonData.urlLesson}
          ></Genially>
        </div>
      )}

      <div className="lessonPageDetails">
        <div className="lessonPageDetailsTitle">
          <Title weigth="black" hasUnderline size="l">
            How to teach the class?
          </Title>
        </div>
        <div className="lessonPageNote">
          <div className="lessonPageNoteImg">
            <img src={Start} />
          </div>
          <div className="lessonPageNoteBody">
            <div className="lessonPageNoteTitle">IMPORTANT</div>
            <div className="lessonPageNoteText">
              Find the teacher key on the upper left corner of each slide on
              every lesson plan.
            </div>
          </div>
          <div className="lessonPageNoteImg">
            <img src={Start} />
          </div>
        </div>
        <div className="lessonPageDetailsCard">
          <CardWithImage image={warmup} title={"Warm up"}>
            {lessonData?.warmUp}
          </CardWithImage>
          <CardWithImage image={vocabulary} title={"Vocabulary"}>
            {lessonData?.vocabulary}
          </CardWithImage>
          <CardWithImage image={input} title={"input"}>
            {lessonData?.input}
          </CardWithImage>
        </div>
        <div className="lessonPageDetailsCard">
          <CardWithImage image={feedback} title={"Grammar"}>
            {lessonData?.feedback}
          </CardWithImage>
          <CardWithImage image={task} title={"Task"}>
            {lessonData?.task}
          </CardWithImage>
        </div>
      </div>
      <div className="lessonPageComments"></div>
      <div className="lessonPageRecommended"></div>
    </div>
  );
};

export default Lesson;
