import axios from "axios";
import { apiUrl } from "../../constants/network";
import {getSessionToken, getUserSession, refreshUser} from "../../session/sessionUtis";

//const { user_Id, pricing_Id, suscription_Id } = req.body;

const validateSuscription = (data, callBack) => {
    let body = JSON.stringify(data);
    var config = {
        data: body,
        method: "post",
        maxBodyLength: Infinity,
        url: apiUrl + "/payment/validatesuscription",
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          refreshUser();
          callBack(response);
        })
        .catch(function (error) {
          callBack(error);
        });
}

const getBilling = (callBack) => {

  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: apiUrl + "/payment/suscriptions/"+getUserSession().user._id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });

}

const getSubscriptionDetails = (callBack) => {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: apiUrl + "/payment/suscriptiondetails/"+getUserSession().user._id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });

}

const  suspendsuscription = (callBack) =>{
  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/payment/suspendsuscription/"+getUserSession().user._id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

const  activatesubscription = (callBack) =>{
  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: apiUrl + "/payment/activatesuscription/"+getUserSession().user._id,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionToken()}`,
    },
  };

  axios(config)
    .then(function (response) {
      callBack(response.data.data);
    })
    .catch(function (error) {
      callBack(error);
    });
}

export {validateSuscription, getBilling, getSubscriptionDetails, suspendsuscription, activatesubscription};