import ChangePasswordForm from "../../../forms/changePasswordForm/changePasswordForm";
import AccountDetailsForm from "../../../forms/accountDetailsForm/AccountDetailsForm";
import { FaUserCircle } from "react-icons/fa"
import React, { useState, useEffect } from 'react'
import { changePassword, updateUser, changeUserPicture } from "../../../../network/user/user";
import { getUserSession, refreshUser } from "../../../../session/sessionUtis";
import {useNavigate} from "react-router-dom";
import { ReactSession } from "react-client-session";
import Button from "../../../common/button";
import Title from "../../../common/title/title";

const AccountDetails = () => {

    const navigate = useNavigate();

    const [image, setImage] = useState(null);

    const [a, sa] = useState("");

    const userData = getUserSession().user;

    const submitChangePassword = (data) => {
        changePassword(data, (response)=>{

        })
    }

    const submitData = (newData) => {
        updateUser(newData, (response)=>{

        });
    }

    const handleImageChange = (target) => {
        setImage(({[target.target.name]:target.target.value, fileInput:target.target}));
    }

    const submitImage = () => {
        changeUserPicture(image, (response)=>{
            if(response?.data?.photoResponse?.Location){
                const actualUser = getUserSession();
                actualUser.user.userImage = response.data.photoResponse.Location;
                ReactSession.set("session", actualUser);
            }

            sa(Date.now());
            navigate("/");
        });
    }

    useEffect(()=>{
        const b = "c"
    },[a]);

    return (
        <div className="accountPage">
        <div className="accountPageDetails">
            <div className="accountPageProfilePicture">

                {
                    userData.userImage ? (
                        <img alt={a} onClick={()=> document.getElementById('fileInput').click()} className="accountPageProfilePictureImg" src={userData.userImage}></img>
                    ) : (
                        <FaUserCircle onClick={()=> document.getElementById('fileInput').click()}></FaUserCircle>
                    )
                }

                <input onChange={handleImageChange} id="fileInput" name="userImage" type="file"></input>

                <Button className="accountPageProfilePictureBtn" disabled={!image} type="black" onClick={submitImage}>Update Image</Button>
            </div>
            <div className="accountPageDetailsForm">
                <AccountDetailsForm setData={submitData}></AccountDetailsForm>
            </div>
        </div>
            <div style={{marginLeft:"3rem"}} className="accountPageDetailsFormExtra">
                <Title weigth="black">Password change</Title>
            </div>
        <div className="accountPagePassword">
            <ChangePasswordForm setData={submitChangePassword}></ChangePasswordForm>
        </div>
        <div className="accountPageBilling"></div>
    </div>
    )
}

export default AccountDetails
